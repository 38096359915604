import React from 'react'

function SmallLogo(props) {
  const { color, height, width } = props
  return (
    <svg
      width={width || 168}
      height={height || 207}
      viewBox="0 0 168 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_443_1763)">
        <path
          d="M149.58 42.7521C139.889 39.0885 135.247 35.1087 133.394 27.1182C133.128 25.9729 132.109 25.1553 130.932 25.1553H123.38C122.392 25.1553 121.477 24.5884 121.083 23.6822C116.622 13.4396 103.269 0 83.178 0C65.7034 0 50.5791 12.9614 46.5693 23.5395C46.1988 24.5151 45.2649 25.1514 44.2191 25.1514H37.0602C35.8947 25.1514 34.8759 25.9536 34.6057 27.0912C32.815 34.6459 28.072 39.1 18.4162 42.7521C7.96545 46.7049 0 53.7197 0 70.4641V204.945C0 206.078 0.918497 206.996 2.05311 206.996H165.947C167.081 206.996 168 206.078 168 204.945V70.4641C168 53.7158 160.035 46.7049 149.584 42.7521H149.58ZM150.653 191.732H17.3472C16.2126 191.732 15.2941 190.815 15.2941 189.681V72.5042C15.2941 58.7484 21.7545 56.5503 29.1989 53.8855C35.7288 51.5485 42.0965 48.8143 45.1376 41.545C45.5273 40.6156 46.4458 40.0179 47.4531 40.0179C48.8964 40.0179 50.5289 40.0179 55.8045 40.0179C56.9314 40.0179 57.9193 39.2813 58.2358 38.2054C60.833 29.3973 67.3358 15.2829 83.178 15.2829C99.0201 15.2829 108.132 26.7943 110.49 38.0627C110.729 39.2157 111.779 40.0179 112.956 40.0179C117.907 40.0179 119.034 40.0179 120.547 40.0179C121.554 40.0179 122.477 40.6156 122.862 41.545C125.904 48.8143 132.271 51.5524 138.805 53.8855C146.249 56.5464 152.71 58.7484 152.71 72.5042V189.681C152.71 190.815 151.791 191.732 150.657 191.732H150.653Z"
          fill={color || '#1f4d25'}
        />
        <path
          d="M88.3725 64.0317H55.16C54.2608 64.0317 53.5314 64.7605 53.5314 65.6591V171.093C53.5314 171.992 54.2608 172.72 55.16 172.72H73.9235C74.8227 172.72 75.5521 171.992 75.5521 171.093V134.002C75.5521 133.308 75.9921 132.687 76.652 132.463C85.8987 129.309 98.831 124.87 102.366 123.524C108.039 121.369 120.493 113.359 120.493 93.5293C120.493 73.6997 110.308 64.0317 88.3725 64.0317ZM87.9943 115.179C85.2349 116.116 81.3911 117.493 77.7365 118.82C76.6752 119.205 75.556 118.418 75.556 117.289V77.5291C75.556 76.6305 76.2854 75.9017 77.1846 75.9017H87.5543C98.5223 75.9017 99.9425 89.2872 99.9425 95.7544C99.9425 102.222 96.1295 112.422 87.9981 115.175L87.9943 115.179Z"
          fill={color || '#1f4d25'}
        />
      </g>
      <defs>
        <clipPath id="clip0_443_1763">
          <rect width="168" height="207" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SmallLogo
