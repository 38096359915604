import { Button as MuiButton, Box } from '@mui/material'
import PropTypes from 'prop-types'
import theme, { FONTS } from 'src/styles/theme'
import { makeStyles } from 'tss-react/mui'
import React from 'react'
import { rem } from 'src/common/utils/css'

const variantType = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TEXT: 'text',
}

const useStyles = makeStyles()((defaultTheme, props) => {
  const { variant, disabled, hover, active } = props

  let button

  switch (variant) {
    case variantType.SECONDARY:
      button = {
        minHeight: rem(40),
        borderRadius: rem(25),
        background: theme.palette.presidio.color.LIGHT_BACKGROUND,
        border: `${rem(2)} solid ${theme.palette.primary.dark}`,
        color: theme.palette.primary.dark,
        paddingLeft: rem(30),
        paddingRight: rem(30),
        '&: hover': {
          background: theme.palette.presidio.color.LIGHT_BACKGROUND,
          border: `${rem(2)} solid ${theme.palette.secondary.dark}`,
          color: theme.palette.secondary.dark,
          boxShadow: `0 ${rem(4)} ${rem(8)} rgba(0, 0, 0, 0.16)`,
        },
        '@media (hover: none)': {
          '&: hover': {
            background: theme.palette.presidio.color.LIGHT_BACKGROUND,
            border: `${rem(2)} solid ${theme.palette.primary.dark}`,
            color: theme.palette.primary.dark,
            boxShadow: 'none',
          },
        },
        '&: active': {
          border: `${rem(2)} solid ${theme.palette.secondary.dark}`,
          color: theme.palette.secondary.dark,
          boxShadow: 'none',
        },
        '&: disabled': {
          color: theme.palette.disabled.dark,
          border: `${rem(2)} solid ${
            theme.palette.presidio.color.BAKER_BEACH_GRAY
          }`,
        },
      }
      break
    case variantType.TEXT: {
      let color
      let textDecorationLine
      let cursor

      if (disabled) {
        cursor = 'auto'
        color = theme.palette.disabled.dark
        textDecorationLine = 'none'
      } else if (hover) {
        cursor = 'pointer'
        color = theme.palette.secondary.dark
        textDecorationLine = 'underline'
      } else if (active) {
        cursor = 'pointer'
        color = theme.palette.secondary.dark
        textDecorationLine = 'none'
      } else {
        cursor = 'pointer'
        color = theme.palette.primary.dark
        textDecorationLine = 'none'
      }

      button = {
        fontFamily: FONTS.ROC_GROTESK,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: color,
        cursor: cursor,
        textDecorationLine: textDecorationLine,
        gap: rem(8),
        '&: hover': {
          textDecorationLine: disabled ? 'none' : 'underline',
          color: disabled
            ? theme.palette.disabled.dark
            : theme.palette.secondary.dark,
        },
        '@media (hover: none)': {
          '&: hover': {
            color: color,
            textDecorationLine: 'none',
          },
        },
        '&: active': {
          textDecorationLine: 'none',
          color: disabled
            ? theme.palette.disabled.dark
            : theme.palette.secondary.dark,
        },
      }
      break
    }
    default:
      button = {
        minHeight: rem(40),
        borderRadius: rem(25),
        background: theme.palette.primary.dark,
        color: theme.palette.presidio.color.NEAR_WHITE,
        paddingLeft: rem(32),
        paddingRight: rem(32),
        '&: hover': {
          background: theme.palette.secondary.dark,
          boxShadow: `0 ${rem(4)} ${rem(8)} rgba(0, 0, 0, 0.16)`,
        },
        '@media (hover: none)': {
          '&: hover': {
            background: theme.palette.primary.dark,
            boxShadow: 'none',
          },
        },
        '&: active': {
          background: theme.palette.secondary.dark,
          boxShadow: 'none',
        },
        '&: disabled': {
          background: theme.palette.presidio.color.BAKER_BEACH_GRAY,
          color: theme.palette.disabled.dark,
        },
      }
      break
  }

  return {
    button: {
      ...button,
      textTransform: 'uppercase',
      fontWeight: '400',
    },
    text: {
      paddingTop: rem(3),
      textAlign: 'center',
    },
  }
})

function Button({
  variant,
  children,
  startIcon,
  endIcon,
  className,
  disabled,
  hover,
  active,
  ...props
}) {
  if (!children) return null

  const { classes } = useStyles({ variant, disabled, hover, active })

  const iconHoverColor = React.useMemo(() => {
    if (disabled) return theme.palette.disabled.dark
    if (variant === variantType.TEXT || variant === variantType.SECONDARY) {
      return theme.palette.secondary.dark
    }
    return theme.palette.presidio.color.NEAR_WHITE
  }, [variant, disabled])

  const iconDefaultColor = React.useMemo(() => {
    if (disabled) return theme.palette.disabled.dark
    if (variant === variantType.TEXT || variant === variantType.SECONDARY) {
      return theme.palette.primary.dark
    }
    return theme.palette.presidio.color.NEAR_WHITE
  }, [variant, disabled])

  const [colorIcon, setColorIcon] = React.useState(iconDefaultColor)

  React.useEffect(() => {
    if (variant === variantType.TEXT) {
      if (hover || active) {
        setColorIcon(iconHoverColor)
      } else {
        setColorIcon(iconDefaultColor)
      }
    }
  }, [hover, active])

  React.useEffect(() => {
    setColorIcon(iconDefaultColor)
  }, [variant, disabled])

  const handleMouseEnter = (e) => {
    setColorIcon(iconHoverColor)
  }

  const handleMouseLeave = (e) => {
    if (variant === variantType.TEXT) {
      if (hover || active) return
    }
    setColorIcon(iconDefaultColor)
  }

  if (variant === variantType.TEXT) {
    return (
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={[classes.button, className].join(' ')}
        disabled={disabled}
        {...props}
      >
        {startIcon && React.cloneElement(startIcon, { color: colorIcon })}
        <Box className={classes.text}>{children}</Box>
        {endIcon && React.cloneElement(endIcon, { color: colorIcon })}
      </Box>
    )
  }

  return (
    <MuiButton
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={[classes.button, className].join(' ')}
      disableElevation
      disabled={disabled}
      startIcon={
        startIcon && React.cloneElement(startIcon, { color: colorIcon })
      }
      endIcon={endIcon && React.cloneElement(endIcon, { color: colorIcon })}
      disableRipple
      {...props}
    >
      <div className={classes.text}>{children}</div>
    </MuiButton>
  )
}

Button.propTypes = {
  variant: PropTypes.oneOf([
    variantType.PRIMARY,
    variantType.SECONDARY,
    variantType.TEXT,
  ]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
}

Button.defaultProps = {
  variant: 'primary',
}

export default Button
