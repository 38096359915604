import { useRouter } from 'next/router'

export function useLinkClickHandler() {
  const router = useRouter()

  function linkClickHandler(action) {
    if (action.target === '_blank') {
      window.open(action.url, '_blank')
    } else {
      router.push(action.url)
    }
  }

  return linkClickHandler
}
