import ExternalLink from 'src/components/icons/ExternalLink'
import { rem } from 'src/common/utils/css'
import theme from 'src/styles/theme'

/**
 *
 * @param {number} height - height of the icon (optional, default: 18).
 * @param {number} width - width of the icon (optional, default: 18).
 * @param {string} color - color of the icon(optional).
 * @param {number} bottomMargin - bottom-margin for adjusting vertical alignment (optional, default: 3).
 *
 * @return Returns an Icon for primary or secondary button variant.
 *
 */

export const ButtonExternalLink = ({ height, width, color, bottomMargin }) => (
  <ExternalLink
    height={height || 18}
    width={width || 18}
    color={color || theme.palette.primary.dark}
    style={{ marginBottom: rem(bottomMargin || 3) }}
  />
)

/**
 *
 * @param {number} height - The height of the icon (optional, default: 18).
 * @param {number} width - The width of the icon (optional, default: 18).
 * @param {string} color - The color of the icon(optional).
 * @param {number} bottomMargin - The bottom margin for adjusting vertical alignment (optional, default: 3).
 *
 * @return Returns an Icon for text button variant.
 *
 */

export const TextButtonExternalLink = ({
  height,
  width,
  color,
  bottomMargin,
}) => (
  <ExternalLink
    height={height || 18}
    width={width || 18}
    color={color || theme.palette.primary.dark}
    style={{ marginBottom: rem(bottomMargin || 3) }}
  />
)
