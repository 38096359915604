import React from 'react'
import PropTypes from 'prop-types'

import CardAlertBanner from 'src/components/alert-banner/CardAlertBanner'

export default function AlertBanner(props) {
  const { data, isGlobal } = props
  if (!data) return null

  const { alert_banner } = data
  if (
    !alert_banner ||
    !Array.isArray(alert_banner) ||
    alert_banner.length === 0
  )
    return null

  return alert_banner.map((item, index) => (
    <CardAlertBanner data={item} key={index} isGlobal={isGlobal} />
  ))
}

AlertBanner.propTypes = {
  data: PropTypes.shape({
    alert_banner: PropTypes.array.isRequired,
  }),
}
