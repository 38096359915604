import DOMPurify from 'isomorphic-dompurify'
import React from 'react'
import Link from 'next/link'
import parse, { domToReact } from 'html-react-parser'
import ExternalLink from 'src/components/icons/ExternalLink'
import theme from 'src/styles/theme'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { rem } from 'src/common/utils/css'
import Button from 'src/common/components/button/Button'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'

const BUTTON_VARIANT = {
  'primary-cta': 'primary',
  'secondary-cta': 'secondary',
}

const linkParser = {
  // eslint-disable-next-line consistent-return
  replace: ({ name, attribs, children }) => {
    if (name === 'a') {
      const { href, class: className, ...rest } = attribs
      if (className?.endsWith('-cta')) {
        return (
          <Link href={href}>
            <a className={className} {...rest}>
              <Button
                variant={BUTTON_VARIANT[className]}
                tabIndex={-1}
                endIcon={isUrlExternal(href) ? <ButtonExternalLink /> : null}
              >
                {domToReact(children)}
              </Button>
            </a>
          </Link>
        )
      }

      return (
        <span>
          <Link href={href || '#'}>
            <a className={className} {...rest}>
              {domToReact(children)}
            </a>
          </Link>
          {isUrlExternal(href) && (
            <span
              style={{
                paddingLeft: rem(2),
              }}
            >
              <ExternalLink
                height={16}
                width={16}
                color={theme.palette.primary.dark}
                style={{ marginBottom: rem(-2) }}
              />
            </span>
          )}
        </span>
      )
    }
  },
}

export default (text, gaTag) => {
  if (text) {
    let formattedText = text.replace(/[\u2028]/g, '')

    if (gaTag) {
      formattedText = formattedText.replace(/<a([^>]*)>/g, (match, attrs) => {
        const existingAttributes = attrs.trim()
        const updatedAnchor = `<a ${existingAttributes} data-ga-location="${gaTag}" data-style="word-break">`
        return updatedAnchor
      })
    }

    // Configure DOMPurify to allow iframes and other elements as needed
    const config = {
      ADD_ATTR: ['target'],
      ADD_TAGS: ['iframe'],
    }

    const sanitizedText = DOMPurify.sanitize(formattedText, config)

    return parse(sanitizedText, linkParser)
  }

  return DOMPurify.sanitize()
}
