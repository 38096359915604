import { Grid, useMediaQuery } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Image from 'next/image'
import PropTypes from 'prop-types'
import theme from 'src/styles/theme'
import { rem } from 'src/common/utils/css'

const useStyles = makeStyles(theme)((props) => ({
  footerLogoBlock: {
    marginTop: rem(19),
    display: 'flex',
    flexDirection: 'column',
    gap: rem(24),
    [theme.breakpoints.up('md')]: {
      marginTop: rem(35),
    },
  },
  sponsors: {
    color: theme.palette.presidio.color.BAKER_BEACH_WHITE,
    paddingTop: '30px',
  },
  gridLargeItem: {
    position: 'relative',
    height: rem(29),
    width: rem(95),
    overflow: 'hidden',
    marginTop: rem(16),
    marginBottom: rem(16),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  gridSmallItem: {
    position: 'relative',
    height: rem(22.5),
    width: rem(75),
    overflow: 'hidden',
    marginTop: rem(16),
    marginBottom: rem(16),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  sectionHead: {
    ...theme.typography.cardTitle,
    color: theme.palette.presidio.color.BAKER_BEACH_WHITE,
  },
  root: {
    paddingBottom: '5px',
  },
  headingDiv: {
    ...theme.typography.h5,
    color: theme.palette.presidio.color.BAKER_BEACH_WHITE,
  },
}))

const Sponsors = (props) => {
  const { primarySponsors, secondarySponsors, title } = props || {}

  const { classes } = useStyles(props)

  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))

  const showSponsorsTitle =
    primarySponsors?.length || secondarySponsors?.length > 0

  return (
    <div className={classes.root}>
      <div className={classes.sponsors}>
        {showSponsorsTitle && mdUp ? (
          <div className={classes.headingDiv}>{title}</div>
        ) : (
          <div role="heading" aria-level="5" className={classes.sectionHead}>
            {title}
          </div>
        )}
      </div>
      <div className={classes.footerLogoBlock}>
        {primarySponsors && primarySponsors.length !== 0 && (
          <Grid
            className={classes.primarySponsorsContainer}
            container
            alignItems="center"
            justifyContent={mdUp ? 'space-between' : 'space-evenly'}
          >
            {primarySponsors.map(
              ({ logo }, index) =>
                logo && (
                  <Grid
                    key={index}
                    className={classes.gridLargeItem}
                    item
                    xs={6}
                    md={1}
                  >
                    <Image
                      src={logo?.url}
                      layout="fill"
                      objectFit="contain"
                      objectPosition="center"
                      alt={logo?.alt || 'sponsor logo'}
                      title={logo?.title}
                      data-ga-location="sponsors"
                    />
                  </Grid>
                )
            )}
          </Grid>
        )}
        {secondarySponsors && secondarySponsors.length !== 0 && (
          <Grid
            container
            alignItems="center"
            justifyContent={mdUp ? 'space-between' : 'space-evenly'}
          >
            {secondarySponsors.map(
              ({ logo }, index) =>
                logo && (
                  <Grid
                    key={index}
                    className={classes.gridSmallItem}
                    item
                    xs={6}
                    md={1}
                  >
                    <Image
                      src={logo?.url}
                      layout="fill"
                      objectFit="contain"
                      objectPosition="center"
                      alt={logo?.alt || 'sponsor logo'}
                      title={logo?.title}
                      data-ga-location="sponsors"
                    />
                  </Grid>
                )
            )}
          </Grid>
        )}
      </div>
    </div>
  )
}

export default Sponsors

Sponsors.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  primarySponsors: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  // eslint-disable-next-line react/no-unused-prop-types
  secondarySponsors: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  // eslint-disable-next-line react/no-unused-prop-types
  title: PropTypes.string,
}
