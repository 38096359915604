import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Sponsors from 'src/components/footer/sponsors/Sponsors'
import theme, { COLOR } from 'src/styles/theme'
import NewsLetter from 'src/components/footer/news-letter/NewsLetter'
import BottomMenu from 'src/components/footer/bottom-menu/BottomMenu'
import SocialMedia from 'src/components/footer/social-media/SocialMedia'
import Image from 'next/image'
import PropTypes from 'prop-types'
import sanitize from 'src/common/utils/js/sanitize'
import { rem } from 'src/common/utils/css'

const useStyles = makeStyles(theme)((props) => ({
  footer: {
    backgroundColor: theme.palette.primary.dark,
    padding: rem(15),
    width: '100%',
  },
  footerContainer: {
    margin: `${rem(208)} auto ${rem(10)} auto`,
    maxWidth: '95%',
    [theme.breakpoints.up('xl')]: {
      maxWidth: rem(1128),
    },
  },
  govSiteContainer: {
    backgroundColor: theme.palette.presidio.color.BAKER_BEACH_WHITE,
  },
  govSite: {
    margin: '0px auto',
    padding: `${rem(16)} ${rem(24)}`,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: rem(16),
    flex: 'none',
    alignSelf: 'stretch',
    flexGrow: '0',
    [theme.breakpoints.up('md')]: {
      padding: '16px 0px',
      maxWidth: '95%',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: rem(1128),
    },
  },
  govSiteText: {
    ...theme.typography.helperText,
    color: theme.palette.presidio.color.BLACK,
    '& p': {
      marginBlockStart: '0px',
      marginBlockEnd: '0px',
    },
  },
  govSiteLink: {
    marginTop: '-3px',
    '& a': {
      ...theme.typography.body.inlineLink,
      ...theme.typography.helperText,
      color: COLOR.BLACK,
      '&:after': {
        ...theme.typography.body.inlineLink['&:after'],
        bottom: '0px',
      },
    },
  },
}))
function Footer(props) {
  const { classes } = useStyles(props)
  const { footerData = {} } = props || {}

  // guards
  if (
    !footerData ||
    Object.keys(footerData).length === 0 ||
    !footerData.footer
  ) {
    return null
  }
  // visibility of sponsor block
  const { footer, newsletterMailingId } = footerData

  const { our_sponser_large, our_sponser_small } = footer

  function showSponsors(primary, secondary) {
    let flag = true

    if (!primary && !secondary) {
      flag = false
    }

    if (primary?.length === 0 && secondary?.length === 0) flag = false

    return flag
  }

  return (
    <footer data-weglot="translate">
      {footer.news_letter && newsletterMailingId !== 'none' && (
        <NewsLetter
          newsletterMailingId={newsletterMailingId}
          NewsLetterData={footer.news_letter}
        />
      )}
      <div className={classes.footer}>
        <div className={classes.footerContainer}>
          <BottomMenu data={footer?.footer_menu?.menu_items} />
          {showSponsors(our_sponser_large, our_sponser_small) && (
            <Sponsors
              primarySponsors={footer.our_sponser_large}
              secondarySponsors={footer.our_sponser_small}
              title={footer.our_sponsors_title}
            />
          )}

          <hr />
          <SocialMedia data={footerData} />
        </div>
      </div>
      <div className={classes.govSiteContainer}>
        <div className={classes.govSite}>
          <Image
            src={footer?.flag}
            alt="US flag image"
            title="US flag image"
            width="28px"
            height="21.16px"
          />
          <div className={classes.govSiteLink}>
            <span data-testid="govText" className={classes.govSiteText}>
              {sanitize(footer?.usa_gov_text)}
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

Footer.propTypes = {
  footerData: PropTypes.shape({
    footer: PropTypes.shape({
      our_sponsors_title: PropTypes.string,
      our_sponser_large: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
      our_sponser_small: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
      social_menu: PropTypes.object,
      copy: PropTypes.object,
      news_letter: PropTypes.array,
      usa_gov_text: PropTypes.string,
      usa_gov_link: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  }),
}
