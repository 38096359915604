import { Fade, Typography, useMediaQuery, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Link from 'next/link'
import Image from 'next/image'
import PropTypes from 'prop-types'
import theme, { COLOR, FONTS, SECONDARY_COLOR } from 'src/styles/theme'
import { APPBAR_HEIGHT } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import {
  ButtonExternalLink,
  TextButtonExternalLink,
} from 'src/common/components/externalSiteIcon'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import Button from 'src/common/components/button/Button'
import ArrowRight from 'src/components/icons/ArrowRight'

const useStyles = makeStyles()((_theme) => ({
  menu: {
    background: theme.palette.presidio.color.NEAR_WHITE,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'scroll',
    zIndex: 100,
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      position: 'absolute',
      zIndex: 1, // should always be lesser than the toolbar
      top: `${parseFloat(APPBAR_HEIGHT.slice(0, -3)) / 2}rem`,
      left: 0,
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.24)',
      transition: 'max-height 0.4s ease-in-out, opacity 0.3s linear',
      maxHeight: 0,
      opacity: 1,
    },
  },
  l2PageCardMobile: {
    display: 'flex',
    flexGrow: 1,
    gap: rem(16),
    background: COLOR.LIGHT_BACKGROUND,
    paddingLeft: rem(24),
    [theme.breakpoints.up('md')]: {
      minWidth: '100%',
      paddingLeft: rem(40),
    },
  },
  l2PageCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(16),
    alignItems: 'flex-start',
    background: COLOR.LIGHT_BACKGROUND,
    padding: `${rem(0)} ${rem(24)} ${rem(16)} ${rem(0)}`,
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      marginTop: rem(40),
      padding: `${rem(36)} ${rem(44)} ${rem(78)} ${rem(40)}`,
      minWidth: '25%',
      maxWidth: '25%',
    },
  },
  l2PageCardHeader: {
    color: theme.palette.primary.dark,
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
  },
  l2PageCardImage: {
    position: 'relative',
    minWidth: '86px',
    height: '71px',
    paddingLeft: rem(24),
    [theme.breakpoints.up('md')]: {
      paddingLeft: rem(40),
    },
    [theme.breakpoints.up('lg')]: {
      height: '140px',
      width: '218px',
      paddingLeft: 'unset',
    },
  },
  l2PageCardDescription: {
    ...theme.typography.body.default,
    color: COLOR.DARK_GRAY,
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
  },
  menuHeroColumn: {
    flex: 1,
    minWidth: '100%',
    background: theme.palette.presidio.color.LIGHT_BACKGROUND,
    position: 'fixed',
    zIndex: 1,
    marginTop: rem(-1),
    paddingTop: rem(1),
    [theme.breakpoints.up('lg')]: {
      position: 'static',
      padding: theme.spacing(8, 0, 0),
      margin: 0,
      background: theme.palette.presidio.color.LIGHT_BACKGROUND,
      backgroundImage: 'url("/assets/Mega-Menu-Background.png")',
      backgroundPosition: 'bottom',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      minWidth: '25%',
      maxWidth: '25%',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '25%',
      maxWidth: '25%',
    },
  },
  menuHeroColumnHeader: {
    color: theme.palette.primary.dark,
    display: 'inline-block',
    padding: theme.spacing(2, 3, 4, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 5, 4, 5),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 3),
    },
    '&: hover': {
      textDecoration: 'underline',
      color: SECONDARY_COLOR.DARK[80],
    },
  },
  menuColumn: {
    flex: 1,
    padding: theme.spacing(3, 3, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    '&:last-child': {
      paddingBottom: rem(64),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 5, 6),
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${rem(106)} ${rem(0)} ${rem(40)} ${rem(0)}`,
      '&:last-child': {
        paddingRight: rem(40),
      },
    },
  },
  menuColumnHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  menuColumnHeader: {
    fontSize: rem(13),
    fontFamily: FONTS.TABLET_GOTHIC,
    color: SECONDARY_COLOR.DARK[80],
    textTransform: 'uppercase',
    fontWeight: 600,
    lineHeight: rem(14),
  },
  menuColumnHeaderLine: {
    borderBottom: `1px solid ${SECONDARY_COLOR.DARK[80]}`,
  },
  menuColumnItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
  menuItem: {
    ...theme.typography.body.default,
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    '&: hover': {
      color: SECONDARY_COLOR.DARK[80],
      textDecoration: 'underline',
    },
  },
}))

const LinkItem = ({ subMenuItem, onClickMenuItem, anchorClassName, gaTag }) => {
  const iconDefaultColor = theme.palette.primary.dark
  const iconHoverColor = SECONDARY_COLOR.DARK[80]
  const [colorIcon, setColorIcon] = useState(iconDefaultColor)

  const handleMouseEnter = (e) => {
    setColorIcon(iconHoverColor)
  }
  const handleMouseLeave = (e) => {
    setColorIcon(iconDefaultColor)
  }
  return (
    <Link key={subMenuItem?.menuid} href={subMenuItem?.url}>
      <a
        onClick={onClickMenuItem}
        onKeyDown={onClickMenuItem}
        className={anchorClassName}
        data-ga-location={gaTag}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {subMenuItem?.title}{' '}
        {isUrlExternal(subMenuItem?.url) && (
          <ButtonExternalLink
            height={16}
            width={16}
            color={colorIcon}
            bottomMargin={-2}
          />
        )}
      </a>
    </Link>
  )
}

export default function MegaMenu(props) {
  const { menuData, onClickMenuItem, isSticky } = props

  const [visible, setVisible] = useState(true)
  const [menuState, setMenuState] = useState(null)
  const [cardHover, setCardHover] = useState(false)
  const timeout = 300

  const { classes } = useStyles()

  // For menu column header logic (different styles as per screen size)
  const xlUp = useMediaQuery(() => theme.breakpoints.up('xl'))
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))

  useEffect(() => {
    setVisible(false)
    setTimeout(() => {
      setMenuState(menuData)
      setVisible(true)
    }, timeout)
  }, [menuData])

  const menuStateData = menuState || menuData
  const linkClickHandler = useLinkClickHandler()

  let megaMenuMargin = '0'
  if (xlUp) megaMenuMargin = '156px'
  else if (lgUp) megaMenuMargin = '40px'

  let l2PageCardContent

  if (menuStateData?.menuCard) {
    if (lgUp) {
      l2PageCardContent = (
        <Fade in={visible} timeout={timeout}>
          <Box
            className={classes.l2PageCard}
            component="a"
            href={menuStateData.menuCard.link?.url}
            data-ga-location={`subnav_${menuStateData.category.toLowerCase()}_tile`}
            onClick={(e) => {
              e.preventDefault()
              onClickMenuItem(e)
              linkClickHandler({ url: menuStateData.menuCard.link?.url })
            }}
            onMouseEnter={() => setCardHover(true)}
            onMouseLeave={() => setCardHover(false)}
          >
            <Typography variant="h3" className={classes.l2PageCardHeader}>
              {menuStateData.menuCard.title}
            </Typography>
            <Box className={classes.l2PageCardImage}>
              <Image
                src={menuStateData.menuCard.image?.url}
                alt={
                  menuStateData.menuCard.image?.alt ||
                  `${menuStateData.category}`
                }
                title={menuStateData.menuCard.image?.title}
                layout="fill"
                objectFit="contain"
              />
            </Box>
            <Typography className={classes.l2PageCardDescription}>
              {menuStateData.menuCard.description}
            </Typography>
            <Button
              variant="text"
              endIcon={
                isUrlExternal(menuStateData.menuCard.link?.url) ? (
                  <TextButtonExternalLink />
                ) : (
                  <ArrowRight />
                )
              }
              hover={cardHover}
            >
              {menuStateData.menuCard.link?.title}
            </Button>
          </Box>
        </Fade>
      )
    } else {
      l2PageCardContent = (
        <Fade in={visible} timeout={timeout}>
          <Box className={classes.l2PageCardMobile}>
            <Box className={classes.l2PageCardImage}>
              <Image
                src={menuStateData.menuCard.image?.url}
                alt={
                  menuStateData.menuCard.image?.alt ||
                  `${menuStateData.category}`
                }
                title={menuStateData.menuCard.image?.title}
                layout="fill"
                objectFit="contain"
              />
            </Box>
            <Box
              className={classes.l2PageCard}
              component="a"
              href={menuStateData.menuCard.link?.url}
              data-ga-location={`subnav_${menuStateData.category.toLowerCase()}_tile`}
              onClick={(e) => {
                e.preventDefault()
                onClickMenuItem(e)
                linkClickHandler({ url: menuStateData.menuCard.link?.url })
              }}
            >
              <Typography variant="h3" className={classes.l2PageCardHeader}>
                {menuStateData.menuCard.title}
              </Typography>
              <Typography className={classes.l2PageCardDescription}>
                {menuStateData.menuCard.description}
              </Typography>
              <Button
                variant="text"
                endIcon={
                  isUrlExternal(menuStateData.menuCard.link?.url) ? (
                    <TextButtonExternalLink />
                  ) : (
                    <ArrowRight />
                  )
                }
              >
                {menuStateData.menuCard.link?.title}
              </Button>
            </Box>
          </Box>
        </Fade>
      )
    }
  }

  return (
    <Box
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: `0 ${isSticky ? megaMenuMargin : 0} `,
        width: '100%',
      }}
    >
      <div className={classes.menu} data-id="mega-menu" data-weglot="translate">
        {menuStateData?.menuCard && l2PageCardContent}
        {Object.values(menuStateData?.menuItems || {}).map((subMenu, index) => (
          <Fade in={visible} timeout={timeout} key={subMenu?.menuid}>
            <div className={classes.menuColumn}>
              <div className={classes.menuColumnHeaderContainer}>
                <div className={classes.menuColumnHeader}>{subMenu?.title}</div>
                <span className={classes.menuColumnHeaderLine} />
              </div>
              <div className={classes.menuColumnItems}>
                {subMenu?.menuitem &&
                  Object.values(subMenu.menuitem).map((subMenuItem) => (
                    <LinkItem
                      subMenuItem={subMenuItem}
                      onClickMenuItem={onClickMenuItem}
                      gaTag={`subnav_${menuStateData?.category.toLowerCase()}_0${
                        +index + 1
                      }`}
                      anchorClassName={classes.menuItem}
                    />
                  ))}
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </Box>
  )
}

MegaMenu.propTypes = {
  menuData: PropTypes.shape({
    menuItems: PropTypes.object,
    menuHeader: PropTypes.string,
    menuCard: PropTypes.object,
  }),
}
