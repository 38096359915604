import React from 'react'

function LinkdinIcon(props) {
  const { color, height, width } = props
  return (
    <svg
      width={width || '18'}
      height={height || '19'}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6861 6.943H10.0287V8.608C10.5102 7.6504 11.745 6.79 13.5999 6.79C17.1558 6.79 18 8.6962 18 12.1936V18.6709H14.4V12.9901C14.4 10.9984 13.9185 9.8752 12.6927 9.8752C10.9926 9.8752 10.2861 11.0857 10.2861 12.9892V18.6709H6.6861V6.943ZM0.513 18.5179H4.113V6.79H0.513V18.5179ZM4.6287 2.9659C4.62883 3.26765 4.56899 3.56641 4.45265 3.84483C4.33631 4.12324 4.16579 4.37576 3.951 4.5877C3.51576 5.02026 2.92663 5.26239 2.313 5.2609C1.70045 5.26049 1.11268 5.01898 0.6768 4.5886C0.46279 4.37594 0.292847 4.12314 0.176709 3.84469C0.0605713 3.56623 0.000521219 3.2676 0 2.9659C0 2.3566 0.243 1.7734 0.6777 1.3432C1.1132 0.912241 1.70122 0.670629 2.3139 0.670899C2.9277 0.670899 3.5163 0.912999 3.951 1.3432C4.3848 1.7734 4.6287 2.3566 4.6287 2.9659Z"
        fill={color || '#FEFCF8'}
      />
    </svg>
  )
}

export default LinkdinIcon
