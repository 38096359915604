import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import axios from 'axios'
import theme from 'src/styles/theme'
import WeatherIcon from 'src/components/icons/WeatherIcon'

const useStyles = makeStyles(theme)((props) => ({
  weather: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    '& a': {
      color: theme.palette.primary.dark,
      textDecoration: 'none',
    },
  },
  sunIcon: {
    height: '24px',
    scale: '0.79',
    position: 'relative',
    flex: 'none',
    order: '0',
    flexGrow: '0',
  },
  weatherText: {
    ...theme.typography.body.default,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    color: theme.palette.presidio.color.DARK_GRAY,
    '& a': {
      color: theme.palette.primary.dark,
      textDecoration: 'none',
    },
  },
}))
const Weather = (props) => {
  const { classes } = useStyles(props)
  const { data = {} } = props || {}
  const [temperature, setTemperature] = useState('Current weather unavailable')
  const [weatherIcon, setWeatherIcon] = useState(null)
  useEffect(() => {
    const getWeather = async () => {
      try {
        const lat = Number(data?.header?.weather_latitude).toFixed(2)
        const lon = Number(data?.header?.weather_longitude).toFixed(2)

        const res = await axios.get(`/api/weather?lat=${lat}&lon=${lon}`)

        const weatherInfo = res.data
        if (res.data.cod === 200) {
          setTemperature(
            `${weatherInfo.weather[0].main} - ${Math.floor(
              weatherInfo?.main?.temp
            )} °F`
          )
          setWeatherIcon(weatherInfo?.weather[0]?.icon)
        }
      } catch (e) {
        console.error(e)
      }
    }
    getWeather()
  }, [])

  // guards
  if (Object.keys(data).length === 0) {
    return null
  }
  return (
    <div className={classes.weather}>
      <div className={classes.sunIcon}>
        <WeatherIcon iconId={weatherIcon} color={theme.palette.primary.dark} />
      </div>
      <div className={classes.weatherText}>{temperature}</div>
    </div>
  )
}

export default Weather

Weather.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.shape({
      weather_latitude: PropTypes.string,
      weather_longitude: PropTypes.string,
    }),
  }),
}
