import React, { useEffect, useRef, useState } from 'react'
import {
  AppBar,
  Box,
  Button,
  ClickAwayListener,
  useMediaQuery,
} from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import { makeStyles } from 'tss-react/mui'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import theme from 'src/styles/theme'
import MegaMenu from 'src/components/main-navigation/mega-menu/MegaMenu'
import Search from 'src/components/main-navigation/search-bar/Search'
import SmallLogo from 'src/components/icons/SmallLogo'
import Drawer from 'src/components/main-navigation/drawer/Drawer'
import { APPBAR_HEIGHT } from 'src/common/constants'
import ExternalLink from 'src/components/icons/ExternalLink'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'

const APPBAR_ANIMATION_DURATION = '0.3s'

const useStyles = makeStyles()((_theme, { isSticky, openMenu }) => ({
  appbar: {
    position: 'sticky',
    top: 0,
    margin: '0 auto',
    zIndex: 100,
    [theme.breakpoints.up('lg')]: {
      transition: `width ${APPBAR_ANIMATION_DURATION} ease-in-out`,
      margin: `calc(-${APPBAR_HEIGHT} / 2) auto 0`,
      padding: isSticky ? '0' : `0 40px`,
    },
    [theme.breakpoints.up('xl')]: {
      padding: isSticky ? '0' : `0 156px`,
    },
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.presidio.color.BAKER_BEACH_WHITE,
    color: theme.palette.presidio.color.DARK_GRAY,
    position: 'absolute',
    top: '0',
    zIndex: 100,
    height: APPBAR_HEIGHT,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.24)',
      borderRadius: isSticky ? '0px' : '5px',
    },
  },
  smallIconlogo: {
    [theme.breakpoints.up('lg')]: {
      cursor: 'pointer',
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.dark,
      opacity: 0,
      height: 0,
      width: 0,
      transition: `all ${APPBAR_ANIMATION_DURATION} ease-in-out`,
    },
  },
  smallIconlogoShow: {
    height: '39px !important',
    width: '32px !important',
    opacity: '1 !important',
    marginRight: '32px !important',
  },
  tabsWrapper: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      gap: '1.5rem',
      '&:lang(es)': {
        gap: '1rem',
      },
    },
    [theme.breakpoints.up('xl')]: {
      gap: '2rem',
      '&:lang(es)': {
        gap: '1.5rem',
      },
    },
    '@media (min-width: 1024px) and (max-width: 1124px)': {
      gap: '1.5rem',
    },
  },
  tabsContainer: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1.5rem',
      '&:lang(es)': {
        gap: '0.5rem',
      },
    },
    [theme.breakpoints.up('xl')]: {
      gap: '2rem',
      '&:lang(es)': {
        gap: '1.5rem',
      },
    },
  },
  tabContainer: {
    [theme.breakpoints.up('lg')]: {
      boxSizing: 'border-box',
      padding: '8px 8px 4px 8px',
    },
  },
  tab: {
    ...theme.typography.h4,
    [theme.breakpoints.up('lg')]: {
      cursor: 'pointer',
      '&: hover': {
        color: theme.palette.primary.dark,
      },
    },
    '@media (min-width:1023px) and (max-width:1048px)': {
      /*
       * Navigation is breaking between 1023 and 1048px.
       * Hardcoded media queries have been added because a
       * media query in the h4 font is changing the font-size
       * to 1.5 rem.
       * */
      fontSize: '1.125rem',
    },
  },
  dividerLineContainer: {
    [theme.breakpoints.up('lg')]: {
      boxSizing: 'border-box',
      padding: '6px 8px 8px 8px',
    },
  },
  dividerLine: {
    [theme.breakpoints.up('lg')]: {
      borderRight: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '99px',
    },
  },
  callToAction: {
    [theme.breakpoints.up('lg')]: {
      ...theme.typography.button,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '25px',
      padding: '7px 32px 10px',
      fontWeight: '500',
      background: theme.palette.primary.dark,
      color: theme.palette.presidio.color.NEAR_WHITE,
      '&: hover': {
        background: theme.palette.secondary.dark,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
      },
      '&: active': {
        background: theme.palette.secondary.dark,
        boxShadow: 'unset',
      },
    },
  },
  megaMenuOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#493726',
    opacity: '0.6',
    zIndex: 99,
  },
}))

export default function MainNavigation(props) {
  const { mainNavigationData, headerData = {}, footerData = {} } = props

  const router = useRouter()

  const [openMenu, setOpenMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [activeTab, setActiveTab] = useState(null)
  const [menuItems, setMenuItems] = useState(null)
  const [hoverTab, setHoverTab] = useState(null)
  const [showOverlay, setShowOverlay] = useState(false)

  // guards
  if (!mainNavigationData || Object.keys(mainNavigationData).length === 0) {
    return null
  }

  // formatting logic for mega menu data
  const formattedMainNavigation =
    Object.values(mainNavigationData?.Megamenu) || []

  let routesArr
  let customLinks

  if (formattedMainNavigation.length !== 0) {
    routesArr = formattedMainNavigation.slice(
      0,
      formattedMainNavigation.length - 1
    )
    customLinks = formattedMainNavigation.pop()
  }

  const formattedCustomLinks = Object.values(customLinks?.menuitem) || []

  const clickAwayHandler = (e) => {
    if (e.key === 'Tab' || e.keyCode === 9) return

    if (!openMenu) return

    const megaMenu = document.querySelector('[data-id="mega-menu"]')

    setOpenMenu(false)
    setAnchorEl(null)
    setMenuItems(null) // to see slide animation on close
    setActiveTab(null)
    setShowOverlay(false)
    if (megaMenu) megaMenu.style.maxHeight = 0
  }

  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))

  const handleTabClick = (e, route) => {
    /**
     * Close mega menu if same tab is selected
     * swap mega menu content if a different tab is selected
     * keep mega menu closed if there is no content available
     */
    if (e.key === 'Tab') {
      return
    }

    const megaMenu = document.querySelector('[data-id="mega-menu"]')

    if (anchorEl === e.target) {
      // we are targeting the same tab which is selected
      setOpenMenu(!openMenu)
      setMenuItems(null) // to see slide animation on close
      setActiveTab(null)
      setAnchorEl(null)
      setShowOverlay(false)
      if (megaMenu) megaMenu.style.maxHeight = 0
      return
    }

    if (route.menuitem && route.menuitem.length !== 0) {
      setOpenMenu(true)
      setAnchorEl(e.target)
      setMenuItems({
        category: route.title,
        menuItems: route.menuitem,
        menuHeader: route.description || route.title,
        menuHeaderUrl: route.url,
        menuCard: route.feature__article,
      })
      setActiveTab(route.title)
      if (megaMenu) megaMenu.style.maxHeight = '1000px'
      setShowOverlay(true)
    } else {
      setOpenMenu(false)
      setAnchorEl(null)
      // setMenuItems(null) to see slide animation on close
      setShowOverlay(false)
      setActiveTab(null)
      if (megaMenu) megaMenu.style.maxHeight = 0
    }
  }

  const handleTabHover = (hovering, route) => {
    setHoverTab(hovering ? route.title : null)
  }

  // For small logo fade in effect
  const smallLogoRef = useRef()

  // Determining navigation bar's position using IntersectionObserver to set isSticky state
  const ref = useRef()
  const [isSticky, setIsSticky] = useState(false)

  const { classes } = useStyles({ isSticky, openMenu })

  useEffect(() => {
    const cachedRef = ref.current
    const smallLogoElement = smallLogoRef.current
    const observer = new IntersectionObserver(
      ([e]) => {
        const flag = e.intersectionRatio < 1

        setIsSticky(flag)

        if (flag) {
          smallLogoElement?.classList.add(classes.smallIconlogoShow)
        } else {
          smallLogoElement?.classList.remove(classes.smallIconlogoShow)
        }
      },
      {
        threshold: [1],
        rootMargin: '-1px 0px 0px 0px', // alternatively, comment this and set `top:0` in the CSS
      }
    )

    observer.observe(cachedRef)

    return function () {
      observer.unobserve(cachedRef)
    }
  }, [isSticky])

  const tabs = (
    <div className={classes.tabsWrapper} data-weglot="translate">
      <div className={classes.tabsContainer}>
        {routesArr &&
          routesArr.map((route) =>
            !route.title ? null : (
              <div key={route.menuid}>
                <div className={classes.tabContainer}>
                  <Box
                    className={classes.tab}
                    sx={{
                      marginTop: '2px', // for vertical alignment
                      color:
                        activeTab === route.title && theme.palette.primary.dark,
                    }}
                    tabIndex={0}
                    aria-label={route.title}
                    onClick={(e) => handleTabClick(e, route)}
                    onKeyDown={(e) => handleTabClick(e, route)}
                    onMouseEnter={(e) => handleTabHover(true, route)}
                    onMouseLeave={(e) => handleTabHover(false, route)}
                    data-ga-location="topnav"
                  >
                    {route.title}
                  </Box>
                  <div
                    style={{
                      borderBottom:
                        activeTab === route.title || hoverTab === route.title
                          ? `2px solid ${theme.palette.primary.main}`
                          : `2px solid transparent`, // prevents layout shift
                    }}
                  />
                </div>
              </div>
            )
          )}
      </div>
      {formattedCustomLinks[1]?.title && (
        <a
          href={formattedCustomLinks[1].url}
          onClick={(e) => {
            e.preventDefault()
            router.push(formattedCustomLinks[1].url)
          }}
          data-ga-location="topnav"
        >
          <Button
            disableRipple
            aria-label={formattedCustomLinks[1].title}
            className={classes.callToAction}
            tabIndex={-1}
            endIcon={
              isUrlExternal(formattedCustomLinks[1]?.url) ? (
                <ExternalLink
                  color={theme.palette.presidio.color.NEAR_WHITE}
                  height={18}
                  width={18}
                  style={{ marginBottom: '1px' }}
                />
              ) : null
            }
          >
            <span style={{ paddingTop: '5px' }}>
              {formattedCustomLinks[1].title}
            </span>
          </Button>
        </a>
      )}
      {formattedCustomLinks[0].title && (
        <Search
          iconColor={theme.palette.presidio.color.DARK_GRAY}
          iconHeight="22"
          iconWidth="22"
          label={formattedCustomLinks[0].title}
          tabIndex={0}
        />
      )}
    </div>
  )

  return (
    <>
      <ClickAwayListener onClickAway={(e) => clickAwayHandler(e)}>
        <AppBar className={classes.appbar} ref={ref} id="appbar">
          <div className={classes.toolbarContainer}>
            <Toolbar disableGutters className={classes.toolbar}>
              {lgUp && (
                <div
                  className={classes.smallIconlogo}
                  onClick={() => router.push('/')}
                  onKeyDown={() => router.push('/')}
                  ref={smallLogoRef}
                >
                  <SmallLogo height="39" width="32" />
                </div>
              )}
              {lgUp ? (
                tabs
              ) : (
                <Drawer
                  mainNavigationData={mainNavigationData}
                  headerData={headerData}
                  footerData={footerData}
                />
              )}
            </Toolbar>
            <MegaMenu
              menuData={menuItems}
              onClickMenuItem={clickAwayHandler}
              isSticky={isSticky}
            />
          </div>
        </AppBar>
      </ClickAwayListener>
      {showOverlay && <div className={classes.megaMenuOverlay} />}
    </>
  )
}

MainNavigation.propTypes = {
  mainNavigationData: PropTypes.shape({
    Megamenu: PropTypes.object.isRequired,
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  headerData: PropTypes.object,
  footerData: PropTypes.object,
}
