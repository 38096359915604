export const APPBAR_HEIGHT = '4.5rem'

export const Link_Target = {
  0: '_self',
  _blank: '_blank',
}

export const COLOR_THEME = {
  THE_BAKER_BEACH: 'baker_beach',
  CRISSY_FIELD: 'crissy_field',
  CRISSY_FIELD_GREEN: 'crissy_field_green',
  CYPRESS_GREEN: 'cypress_green',
  ROOFTOP_RED: 'rooftop_red',
  BACKGROUND: 'background', // Is equivalent to the light_background color
  NEAR_WHITE: 'near_white',
  BAKER_BEACH_WHITE: 'baker_beach_white',
}

export const GRAPHIC_PATTERN = {
  BEACH_STRAWBERRY: 'beach_strawberry',
  MANZANITA: 'manzanita',
  PELICAN: 'pelican',
  FOREST: 'forest',
  LAND_SEA: 'land_sea',
}

export const PHOTO_FRAME_VARIANT = {
  DOME_TALL: 'dome_tall',
  DOME_SHORT: 'dome_short',
  WINFIELD_HALF_TALL: 'winfield_half_tall',
  WINFIELD_HALF_SHORT: 'winfield_half_short',
  WINFIELD_TALL: 'winfield_tall',
  WINFIELD_SHORT: 'winfield_short',
  SEMI_CIRCLE_IMAGE_LEFT: 'semi_circle_image_left',
  SEMI_CIRCLE_IMAGE_RIGHT: 'semi_circle_image_right',
}

export const PHOTO_FRAME_BASE64_STRING = {
  DOM_SHORT:
    'PHN2ZyB3aWR0aD0iNTIwIiBoZWlnaHQ9IjM3NSIgdmlld0JveD0iMCAwIDUyMCAzNzUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8cGF0aCBkPSJNMC40NzYzMTggMTAyLjI5M1YzNzQuMzZINTE5LjIwNFY5Mi41MjkzQzUxMy40MDIgODcuNjY2OSA1MDcuNDcxIDgyLjk1ODggNTAxLjM3OSA3OC40MDVDNDM2LjEzOSAyOS44MTg5IDM1NC4yOTkgMC44NzU2MSAyNjUuNDMzIDAuODc1NjFDMTYyLjgzNSAwLjg3NTYxIDY5LjU4NDIgMzkuNDI4MSAwLjQ3NjMxOCAxMDIuMjkzWiIgZmlsbD0idXJsKCNwYXR0ZXJuMCkiLz4KPGRlZnM+CjxwYXR0ZXJuIGlkPSJwYXR0ZXJuMCIgcGF0dGVybkNvbnRlbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHdpZHRoPSIxIiBoZWlnaHQ9IjEiPgo8dXNlIHhsaW5rOmhyZWY9IiNpbWFnZTBfNjQ1M18zNTEyIiB0cmFuc2Zvcm09Im1hdHJpeCgwLjAxNTYyNSAwIDAgMC4wMjE3MDE0IDAgLTAuMTk0NDQ0KSIvPgo8L3BhdHRlcm4+CjxpbWFnZSBpZD0iaW1hZ2UwXzY0NTNfMzUxMiIgd2lkdGg9IjY0IiBoZWlnaHQ9IjY0IiB4bGluazpocmVmPSJkYXRhOmltYWdlL3BuZztiYXNlNjQsaVZCT1J3MEtHZ29BQUFBTlNVaEVVZ0FBQUVBQUFBQkFDQVlBQUFDcWFYSGVBQUFBQVhOU1IwSUFyczRjNlFBQUFPTkpSRUZVZUY3dDIwRU9oRUFJUkZHNC82RjdEdkVuWWVGenJ5UUl2NnBCZDJiZWhPdTlkUHZzYm9nK2srTkxnQXJRQXFtSmN3OWlBQWhTZ1pLQjNJSmtrQXlTUVRKNENpRStnQThvQmVnMG1IM0FpMDg0UDg5SGhxd0VxSUEyMDlJQ3NRZGpBZWFaSWdhQVlLeEJETUNBWXk4Zlh3QUlnaUFJY29KcEpFWUdJNFZqQjNZcmJDOWdMMkF2a0NCNDNjTTVQZ1pnQUFaZ1FGbk5aQWhkR3lrUUJFRVFCRUVRREJtZ0FtMmdsTS96K1FVWWlzWVVHb2xkTzdrWTMySUVBekNnNlJnSVJnakZBc3crQWdSQk1OWWdCbUNBVDJUQ1lmb1BQei9IQ3FRQ1gxZUJIekhudjdDN1doQlNBQUFBQUVsRlRrU3VRbUNDIi8+CjwvZGVmcz4KPC9zdmc+Cg==',
  DOM_TALL:
    'PHN2ZyB3aWR0aD0iMzYyIiBoZWlnaHQ9IjU4MiIgdmlld0JveD0iMCAwIDM2MiA1ODIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8cGF0aCBkPSJNMC4zMjMwNTkgNTcuNzI1M1Y1ODEuOTU4SDM2MS4zNThWNTIuMzIxMkMzMTQuMTQ3IDIwLjM3MTQgMjUyLjMzOCAwLjk4MjQyMiAxODQuNjg5IDAuOTgyNDIyQzExMy4yODYgMC45ODI0MjIgNDguNDA1NCAyMi41NjIyIDAuMzIzMDU5IDU3LjcyNTNaIiBmaWxsPSJ1cmwoI3BhdHRlcm4wKSIvPgo8ZGVmcz4KPHBhdHRlcm4gaWQ9InBhdHRlcm4wIiBwYXR0ZXJuQ29udGVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgd2lkdGg9IjEiIGhlaWdodD0iMSI+Cjx1c2UgeGxpbms6aHJlZj0iI2ltYWdlMF82NDUzXzM1NDYiIHRyYW5zZm9ybT0ibWF0cml4KDAuMDI1MTQzNyAwIDAgMC4wMTU2MjUgLTAuMzA0NTk4IDApIi8+CjwvcGF0dGVybj4KPGltYWdlIGlkPSJpbWFnZTBfNjQ1M18zNTQ2IiB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHhsaW5rOmhyZWY9ImRhdGE6aW1hZ2UvcG5nO2Jhc2U2NCxpVkJPUncwS0dnb0FBQUFOU1VoRVVnQUFBRUFBQUFCQUNBWUFBQUNxYVhIZUFBQUFBWE5TUjBJQXJzNGM2UUFBQU9OSlJFRlVlRjd0MjBFT2hFQUlSRkc0LzZGN0R2RW5ZZUZ6cnlRSXY2cEJkMmJlaE91OWRQdnNib2craytOTGdBclFBcW1KY3c5aUFBaFNnWktCM0lKa2tBeVNRVEo0Q2lFK2dBOG9CZWcwbUgzQWkwODRQODlIaHF3RXFJQTIwOUlDc1FkakFlYVpJZ2FBWUt4QkRNQ0FZeThmWHdBSWdpQUljb0pwSkVZR0k0VmpCM1lyYkM5Z0wyQXZrQ0I0M2NNNVBnWmdBQVpnUUZuTlpBaGRHeWtRQkVFUUJFRVFEQm1nQW0yZ2xNL3orUVVZaXNZVUdvbGRPN2tZMzJJRUF6Q2c2UmdJUmdqRkFzdytBZ1JCTU5ZZ0JtQ0FUMlRDWWZvUFB6L0hDcVFDWDFlQkh6SG52N0M3V2hCU0FBQUFBRWxGVGtTdVFtQ0MiLz4KPC9kZWZzPgo8L3N2Zz4K',
  SEMI_CIRCLE_IMAGE_LEFT:
    'PHN2ZyB3aWR0aD0iNzA4IiBoZWlnaHQ9IjQ5MCIgdmlld0JveD0iMCAwIDcwOCA0OTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8cGF0aCBkPSJNMCAwSDcwOEM3MDggMCA2NjIgMTE1IDY2MiAyNDNDNjYyIDM3MSA3MDggNDkwIDcwOCA0OTBIMFYwWiIgZmlsbD0iI0Q5RDlEOSIvPgo8cGF0aCBkPSJNMCAwSDcwOEM3MDggMCA2NjIgMTE1IDY2MiAyNDNDNjYyIDM3MSA3MDggNDkwIDcwOCA0OTBIMFYwWiIgZmlsbD0idXJsKCNwYXR0ZXJuMCkiLz4KPGRlZnM+CjxwYXR0ZXJuIGlkPSJwYXR0ZXJuMCIgcGF0dGVybkNvbnRlbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHdpZHRoPSIxIiBoZWlnaHQ9IjEiPgo8dXNlIHhsaW5rOmhyZWY9IiNpbWFnZTBfNjM5NF8yMTQyMzQiIHRyYW5zZm9ybT0ibWF0cml4KDAuMDE1NjI1IDAgMCAwLjAyMjU3NjUgMCAtMC4yMjI0NDkpIi8+CjwvcGF0dGVybj4KPGltYWdlIGlkPSJpbWFnZTBfNjM5NF8yMTQyMzQiIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgeGxpbms6aHJlZj0iZGF0YTppbWFnZS9wbmc7YmFzZTY0LGlWQk9SdzBLR2dvQUFBQU5TVWhFVWdBQUFFQUFBQUJBQ0FZQUFBQ3FhWEhlQUFBQUFYTlNSMElBcnM0YzZRQUFBT05KUkVGVWVGN3QyMEVPaEVBSVJGRzQvNkY3RHZFblllRnpyeVFJdjZwQmQyYmVoT3U5ZFB2c2JvZytrK05MZ0FyUUFxbUpjdzlpQUFoU2daS0IzSUpra0F5U1FUSjRDaUUrZ0E4b0JlZzBtSDNBaTA4NFA4OUhocXdFcUlBMjA5SUNzUWRqQWVhWklnYUFZS3hCRE1DQVl5OGZYd0FJZ2lBSWNvSnBKRVlHSTRWakIzWXJiQzlnTDJBdmtDQjQzY001UGdaZ0FBWmdRRm5OWkFoZEd5a1FCRUVRQkVFUURCbWdBbTJnbE0veitRVVlpc1lVR29sZE83a1kzMklFQXpDZzZSZ0lSZ2pGQXN3K0FnUkJNTllnQm1DQVQyVENZZm9QUHovSENxUUNYMWVCSHpIbnY3QzdXaEJTQUFBQUFFbEZUa1N1UW1DQyIvPgo8L2RlZnM+Cjwvc3ZnPgo=',
  SEMI_CIRCLE_IMAGE_RIGHT:
    'PHN2ZyB3aWR0aD0iNzA4IiBoZWlnaHQ9IjQ5MCIgdmlld0JveD0iMCAwIDcwOCA0OTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8cGF0aCBkPSJNMCAwSDcwOEM3MDggMCA3MDggMTE1IDcwOCAyNDNDNzA4IDM3MSA3MDggNDkwIDcwOCA0OTBIMEMwIDQ5MCA0My41IDM3Mi41IDQzLjUgMjQ1QzQzLjUgMTE3LjUgMCAwIDAgMFoiIGZpbGw9IiNEOUQ5RDkiLz4KPHBhdGggZD0iTTAgMEg3MDhDNzA4IDAgNzA4IDExNSA3MDggMjQzQzcwOCAzNzEgNzA4IDQ5MCA3MDggNDkwSDBDMCA0OTAgNDMuNSAzNzIuNSA0My41IDI0NUM0My41IDExNy41IDAgMCAwIDBaIiBmaWxsPSJ1cmwoI3BhdHRlcm4wKSIvPgo8ZGVmcz4KPHBhdHRlcm4gaWQ9InBhdHRlcm4wIiBwYXR0ZXJuQ29udGVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgd2lkdGg9IjEiIGhlaWdodD0iMSI+Cjx1c2UgeGxpbms6aHJlZj0iI2ltYWdlMF82Mzk0XzIxNDIzNSIgdHJhbnNmb3JtPSJtYXRyaXgoMC4wMTU2MjUgMCAwIDAuMDIyNTc2NSAwIC0wLjIyMjQ0OSkiLz4KPC9wYXR0ZXJuPgo8aW1hZ2UgaWQ9ImltYWdlMF82Mzk0XzIxNDIzNSIgd2lkdGg9IjY0IiBoZWlnaHQ9IjY0IiB4bGluazpocmVmPSJkYXRhOmltYWdlL3BuZztiYXNlNjQsaVZCT1J3MEtHZ29BQUFBTlNVaEVVZ0FBQUVBQUFBQkFDQVlBQUFDcWFYSGVBQUFBQVhOU1IwSUFyczRjNlFBQUFPTkpSRUZVZUY3dDIwRU9oRUFJUkZHNC82RjdEdkVuWWVGenJ5UUl2NnBCZDJiZWhPdTlkUHZzYm9nK2srTkxnQXJRQXFtSmN3OWlBQWhTZ1pLQjNJSmtrQXlTUVRKNENpRStnQThvQmVnMG1IM0FpMDg0UDg5SGhxd0VxSUEyMDlJQ3NRZGpBZWFaSWdhQVlLeEJETUNBWXk4Zlh3QUlnaUFJY29KcEpFWUdJNFZqQjNZcmJDOWdMMkF2a0NCNDNjTTVQZ1pnQUFaZ1FGbk5aQWhkR3lrUUJFRVFCRUVRREJtZ0FtMmdsTS96K1FVWWlzWVVHb2xkTzdrWTMySUVBekNnNlJnSVJnakZBc3crQWdSQk1OWWdCbUNBVDJUQ1lmb1BQei9IQ3FRQ1gxZUJIekhudjdDN1doQlNBQUFBQUVsRlRrU3VRbUNDIi8+CjwvZGVmcz4KPC9zdmc+Cg==',
  WINFIELD_HALF_TALL:
    'PHN2ZyB3aWR0aD0iMzYyIiBoZWlnaHQ9IjU4MiIgdmlld0JveD0iMCAwIDM2MiA1ODIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8cGF0aCBkPSJNMzYwLjc1MyAxLjA1NTkyQzMzMS4xNDcgMS42MDc0OCAyNzAuNjY1IDIxLjEzMjYgMjcwLjY2NSA3Ni4zOTg4SDIyNy45ODlDMjI3Ljk4OSAxOTAuMzg4IDQyLjQyODcgOTUuNDgyOCA0Mi40Mjg3IDIwNy42MzNIMC4zNjk3NTFWNTgxLjk1OEgzNjEuNDA0VjAuOTgyNDIySDM2MC43NTNWMS4wNTU5MloiIGZpbGw9InVybCgjcGF0dGVybjApIi8+CjxkZWZzPgo8cGF0dGVybiBpZD0icGF0dGVybjAiIHBhdHRlcm5Db250ZW50VW5pdHM9Im9iamVjdEJvdW5kaW5nQm94IiB3aWR0aD0iMSIgaGVpZ2h0PSIxIj4KPHVzZSB4bGluazpocmVmPSIjaW1hZ2UwXzY0NTNfMzQ4MiIgdHJhbnNmb3JtPSJtYXRyaXgoMC4wMjUxNDM3IDAgMCAwLjAxNTYyNSAtMC4zMDQ1OTggMCkiLz4KPC9wYXR0ZXJuPgo8aW1hZ2UgaWQ9ImltYWdlMF82NDUzXzM0ODIiIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgeGxpbms6aHJlZj0iZGF0YTppbWFnZS9wbmc7YmFzZTY0LGlWQk9SdzBLR2dvQUFBQU5TVWhFVWdBQUFFQUFBQUJBQ0FZQUFBQ3FhWEhlQUFBQUFYTlNSMElBcnM0YzZRQUFBT05KUkVGVWVGN3QyMEVPaEVBSVJGRzQvNkY3RHZFblllRnpyeVFJdjZwQmQyYmVoT3U5ZFB2c2JvZytrK05MZ0FyUUFxbUpjdzlpQUFoU2daS0IzSUpra0F5U1FUSjRDaUUrZ0E4b0JlZzBtSDNBaTA4NFA4OUhocXdFcUlBMjA5SUNzUWRqQWVhWklnYUFZS3hCRE1DQVl5OGZYd0FJZ2lBSWNvSnBKRVlHSTRWakIzWXJiQzlnTDJBdmtDQjQzY001UGdaZ0FBWmdRRm5OWkFoZEd5a1FCRUVRQkVFUURCbWdBbTJnbE0veitRVVlpc1lVR29sZE83a1kzMklFQXpDZzZSZ0lSZ2pGQXN3K0FnUkJNTllnQm1DQVQyVENZZm9QUHovSENxUUNYMWVCSHpIbnY3QzdXaEJTQUFBQUFFbEZUa1N1UW1DQyIvPgo8L2RlZnM+Cjwvc3ZnPgo=',
  WINFIELD_HALF_SHORT:
    'PHN2ZyB3aWR0aD0iNTIwIiBoZWlnaHQ9IjQxNiIgdmlld0JveD0iMCAwIDUyMCA0MTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8cGF0aCBkPSJNNTE4LjMxOSAwLjM3NzM4QzQ3NS41ODcgMS4wNDUwNCAzODguODQgMjMuNTExNyAzODguODQgODcuMTcyOEgzMjcuNDYyQzMyNy40NjIgMjE4LjU2OCA2MC43NzUxIDEwOS4yMDYgNjAuNzc1MSAyMzguNDMxSDAuNTIyOTE5VjQxNS4zNkg1MTkuMjUxVjAuMzc3MzhINTE4LjMxOVoiIGZpbGw9InVybCgjcGF0dGVybjApIi8+CjxkZWZzPgo8cGF0dGVybiBpZD0icGF0dGVybjAiIHBhdHRlcm5Db250ZW50VW5pdHM9Im9iamVjdEJvdW5kaW5nQm94IiB3aWR0aD0iMSIgaGVpZ2h0PSIxIj4KPHVzZSB4bGluazpocmVmPSIjaW1hZ2UwXzY0NTNfMzUwMSIgdHJhbnNmb3JtPSJtYXRyaXgoMC4wMTU2MjUgMCAwIDAuMDE5NTMxMiAwIC0wLjEyNSkiLz4KPC9wYXR0ZXJuPgo8aW1hZ2UgaWQ9ImltYWdlMF82NDUzXzM1MDEiIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgeGxpbms6aHJlZj0iZGF0YTppbWFnZS9wbmc7YmFzZTY0LGlWQk9SdzBLR2dvQUFBQU5TVWhFVWdBQUFFQUFBQUJBQ0FZQUFBQ3FhWEhlQUFBQUFYTlNSMElBcnM0YzZRQUFBT05KUkVGVWVGN3QyMEVPaEVBSVJGRzQvNkY3RHZFblllRnpyeVFJdjZwQmQyYmVoT3U5ZFB2c2JvZytrK05MZ0FyUUFxbUpjdzlpQUFoU2daS0IzSUpra0F5U1FUSjRDaUUrZ0E4b0JlZzBtSDNBaTA4NFA4OUhocXdFcUlBMjA5SUNzUWRqQWVhWklnYUFZS3hCRE1DQVl5OGZYd0FJZ2lBSWNvSnBKRVlHSTRWakIzWXJiQzlnTDJBdmtDQjQzY001UGdaZ0FBWmdRRm5OWkFoZEd5a1FCRUVRQkVFUURCbWdBbTJnbE0veitRVVlpc1lVR29sZE83a1kzMklFQXpDZzZSZ0lSZ2pGQXN3K0FnUkJNTllnQm1DQVQyVENZZm9QUHovSENxUUNYMWVCSHpIbnY3QzdXaEJTQUFBQUFFbEZUa1N1UW1DQyIvPgo8L2RlZnM+Cjwvc3ZnPgo=',
  WINFIELD_TALL:
    'PHN2ZyB3aWR0aD0iMzYyIiBoZWlnaHQ9IjY4NiIgdmlld0JveD0iMCAwIDM2MiA2ODYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8cGF0aCBkPSJNMTczLjE5NyAwLjIzNjY5NEMxNDYuNDI0IDQuMDY4NjMgMTI1LjA0NCAyNy40ODA5IDEyMC45MTggNTUuODQ2NUg4MC4zNTk4QzczLjA5MjMgNzcuOTAzNSA1NS4yNzQ5IDg1LjY2MDggMzYuOTQxOSA5Mi4xNTY0QzE3LjM4OTcgOTkuMTY2IDAuNDE2MzgyIDEwNC45MTQgMC40MTYzODIgMTQwLjk5VjY4NS42MzhIMzYxLjQ1MVYxNDAuOTlDMzYxLjQ1MSAxMDQuOTE0IDM0NC40NzggOTkuMTY2IDMyNC45MjYgOTIuMTU2NEMzMDYuNjM5IDg1LjY2MDggMjg4Ljc3NSA3Ny45MDM1IDI4MS41NTQgNTUuODQ2NUgyNDAuOTVDMjM2LjgyNCAyNy40ODA5IDIxNS40NDMgNC4wNjg2MyAxODguNzE3IDAuMjM2Njk0SDE3My4xOTdaIiBmaWxsPSJ1cmwoI3BhdHRlcm4wKSIvPgo8ZGVmcz4KPHBhdHRlcm4gaWQ9InBhdHRlcm4wIiBwYXR0ZXJuQ29udGVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgd2lkdGg9IjEiIGhlaWdodD0iMSI+Cjx1c2UgeGxpbms6aHJlZj0iI2ltYWdlMF82NDUzXzM0ODAiIHRyYW5zZm9ybT0ibWF0cml4KDAuMDI5NjYzMSAwIDAgMC4wMTU2MjUgLTAuNDQ5MjE5IDApIi8+CjwvcGF0dGVybj4KPGltYWdlIGlkPSJpbWFnZTBfNjQ1M18zNDgwIiB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHhsaW5rOmhyZWY9ImRhdGE6aW1hZ2UvcG5nO2Jhc2U2NCxpVkJPUncwS0dnb0FBQUFOU1VoRVVnQUFBRUFBQUFCQUNBWUFBQUNxYVhIZUFBQUFBWE5TUjBJQXJzNGM2UUFBQU9OSlJFRlVlRjd0MjBFT2hFQUlSRkc0LzZGN0R2RW5ZZUZ6cnlRSXY2cEJkMmJlaE91OWRQdnNib2craytOTGdBclFBcW1KY3c5aUFBaFNnWktCM0lKa2tBeVNRVEo0Q2lFK2dBOG9CZWcwbUgzQWkwODRQODlIaHF3RXFJQTIwOUlDc1FkakFlYVpJZ2FBWUt4QkRNQ0FZeThmWHdBSWdpQUljb0pwSkVZR0k0VmpCM1lyYkM5Z0wyQXZrQ0I0M2NNNVBnWmdBQVpnUUZuTlpBaGRHeWtRQkVFUUJFRVFEQm1nQW0yZ2xNL3orUVVZaXNZVUdvbGRPN2tZMzJJRUF6Q2c2UmdJUmdqRkFzdytBZ1JCTU5ZZ0JtQ0FUMlRDWWZvUFB6L0hDcVFDWDFlQkh6SG52N0M3V2hCU0FBQUFBRWxGVGtTdVFtQ0MiLz4KPC9kZWZzPgo8L3N2Zz4K',
  WINFIELD_SHORT:
    'PHN2ZyB3aWR0aD0iNTIwIiBoZWlnaHQ9IjM3NSIgdmlld0JveD0iMCAwIDUyMCAzNzUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8cGF0aCBkPSJNMTczLjU3IDg2LjU2SDExNS4zOUMxMDUuMTIzIDEyMC4yNCA3OS4zODY2IDEzMi4wNDYgNTMuMDY4NyAxNDIuMDA1QzI0Ljk3MSAxNTIuNjUyIDAuNTY5NTggMTYxLjQ1MiAwLjU2OTU4IDIxNi40NjNWMzc0LjM2SDUxOS4yOTdWMjE2LjQ2M0M1MTkuMjk3IDE2MS40NTIgNDk0LjkzIDE1Mi42NTIgNDY2Ljc5OCAxNDIuMDA1QzQ0MC40OCAxMzIuMDQ2IDQxNC44ODEgMTIwLjI3NiA0MDQuNDc3IDg2LjU2SDM0Ni4yOTdDMzM5Ljc5NCAzOS4xNTQ4IDMwMy41MTcgMC44NzU2MSAyNjAuMDE5IDAuODc1NjFDMjE2LjUyMSAwLjg3NTYxIDE4MC4yMSAzOS4xNTQ4IDE3My43MDcgODYuNTYiIGZpbGw9InVybCgjcGF0dGVybjApIi8+CjxkZWZzPgo8cGF0dGVybiBpZD0icGF0dGVybjAiIHBhdHRlcm5Db250ZW50VW5pdHM9Im9iamVjdEJvdW5kaW5nQm94IiB3aWR0aD0iMSIgaGVpZ2h0PSIxIj4KPHVzZSB4bGluazpocmVmPSIjaW1hZ2UwXzY0NTNfMzQ4MSIgdHJhbnNmb3JtPSJtYXRyaXgoMC4wMTU2MjUgMCAwIDAuMDIxNzAxNCAwIC0wLjE5NDQ0NCkiLz4KPC9wYXR0ZXJuPgo8aW1hZ2UgaWQ9ImltYWdlMF82NDUzXzM0ODEiIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgeGxpbms6aHJlZj0iZGF0YTppbWFnZS9wbmc7YmFzZTY0LGlWQk9SdzBLR2dvQUFBQU5TVWhFVWdBQUFFQUFBQUJBQ0FZQUFBQ3FhWEhlQUFBQUFYTlNSMElBcnM0YzZRQUFBT05KUkVGVWVGN3QyMEVPaEVBSVJGRzQvNkY3RHZFblllRnpyeVFJdjZwQmQyYmVoT3U5ZFB2c2JvZytrK05MZ0FyUUFxbUpjdzlpQUFoU2daS0IzSUpra0F5U1FUSjRDaUUrZ0E4b0JlZzBtSDNBaTA4NFA4OUhocXdFcUlBMjA5SUNzUWRqQWVhWklnYUFZS3hCRE1DQVl5OGZYd0FJZ2lBSWNvSnBKRVlHSTRWakIzWXJiQzlnTDJBdmtDQjQzY001UGdaZ0FBWmdRRm5OWkFoZEd5a1FCRUVRQkVFUURCbWdBbTJnbE0veitRVVlpc1lVR29sZE83a1kzMklFQXpDZzZSZ0lSZ2pGQXN3K0FnUkJNTllnQm1DQVQyVENZZm9QUHovSENxUUNYMWVCSHpIbnY3QzdXaEJTQUFBQUFFbEZUa1N1UW1DQyIvPgo8L2RlZnM+Cjwvc3ZnPgo=',
}

// Icon codes are coming from the openweather map API
export const WEATHER_ICON = {
  '01d': 'day_clear_sky',
  '01n': 'night_clear_sky',
  '02d': 'day_few_clouds',
  '02n': 'night_few_clouds',
  '03d': 'day_scattered_clouds',
  '03n': 'night_scattered_clouds',
  '04d': 'day_broken_clouds',
  '04n': 'night_broken_clouds',
  '09d': 'day_shower_rain',
  '09n': 'night_shower_rain',
  '10d': 'day_rain',
  '10n': 'night_rain',
  '11d': 'day_thunderstorm',
  '11n': 'night_thunderstorm',
  '13d': 'day_snow',
  '13n': 'night_snow',
  '50d': 'day_mist',
  '50n': 'night_mist',
}

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
