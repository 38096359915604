function Mail2(props) {
  const { color, height, width } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '41'}
      height={height || '38'}
      viewBox="0 0 41 38"
      fill="none"
    >
      <path
        d="M7.16732 6.33334H33.834C35.6673 6.33334 37.1673 7.75834 37.1673 9.50001V28.5C37.1673 30.2417 35.6673 31.6667 33.834 31.6667H7.16732C5.33398 31.6667 3.83398 30.2417 3.83398 28.5V9.50001C3.83398 7.75834 5.33398 6.33334 7.16732 6.33334Z"
        stroke={color || '#1F4D25'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.1673 9.5L20.5007 20.5833L3.83398 9.5"
        stroke={color || '#1F4D25'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Mail2
