import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import sanitize from 'src/common/utils/js/sanitize'
import { rem } from 'src/common/utils/css'
import AlertTriangle from 'src/components/icons/AlertTriangle'
import Bell from 'src/components/icons/Bell'
import Info from 'src/components/icons/Info'
import theme from 'src/styles/theme'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import ExternalLink from 'src/components/icons/ExternalLink'

const ALERT_TYPE = {
  WARNING: 'warning',
  NOTIFICATION: 'notification',
  INFORMATIONAL: 'informational',
}

const useStyles = makeStyles()((defaultTheme, props) => {
  const { backgroundColor, color, isGlobal } = props

  return {
    container: {
      position: isGlobal ? 'relative' : 'static',
      zIndex: isGlobal ? 100 : 'unset',
      backgroundColor: backgroundColor,
      borderBottom: `${rem(1)} solid ${color}`,
      width: '100%',
      padding: `${rem(16)} ${rem(24)}`,
      [theme.breakpoints.up('md')]: {
        padding: `${rem(16)} ${rem(40)}`,
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(16)} ${rem(156)}`,
      },
    },
    text: {
      color: color,
    },
    title: {
      marginLeft: rem(10),
      ...theme.typography.body.bold,
    },
    description: {
      ...theme.typography.smallBody.default,
      margin: `${rem(12)} 0 ${rem(12)} 0`,
    },
    link: {
      ...theme.typography.smallBody.inlineLink,
      display: 'inline-block',
      cursor: 'pointer',
    },
  }
})

export default function CardAlertBanner(props) {
  const { data, isGlobal } = props
  if (!data) return null

  const { title, description, link, bannerType } = data
  if (!title && !description) return null

  let backgroundColor
  let color
  switch (bannerType) {
    case ALERT_TYPE.INFORMATIONAL:
      backgroundColor = theme.palette.info.light
      color = theme.palette.info.main
      break

    case ALERT_TYPE.NOTIFICATION:
      backgroundColor = theme.palette.warning.light
      color = theme.palette.warning.main
      break

    default:
      backgroundColor = theme.palette.error.light
      color = theme.palette.error.main
  }

  const { classes } = useStyles({ backgroundColor, color, isGlobal })

  const icon = React.useMemo(() => {
    if (bannerType === ALERT_TYPE.INFORMATIONAL)
      return <Info color={theme.palette.info.main} />
    if (bannerType === ALERT_TYPE.NOTIFICATION)
      return <Bell color={theme.palette.warning.main} />
    return <AlertTriangle color={theme.palette.error.main} />
  }, [bannerType])

  const linkClickHandler = useLinkClickHandler()

  return (
    <div className={`${classes.container} ${!isGlobal && `module`}`}>
      <Stack direction="row" alignItems="center">
        <Box display="flex" alignItems="center">
          {icon}
        </Box>
        <Typography className={[classes.text, classes.title].join(' ')}>
          {title}
        </Typography>
      </Stack>
      <div className={[classes.text, classes.description].join(' ')}>
        {sanitize(description, 'alert')}
      </div>
      {link && (
        <a
          href={link.url}
          onClick={(e) => {
            e.preventDefault()
            linkClickHandler(link)
          }}
          data-ga-location="alert"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            className={[classes.text, classes.link].join(' ')}
            aria-label="Learn more about this alert"
          >
            {link.title || 'Learn More'}
          </Typography>
          {isUrlExternal(link?.url) && (
            <div style={{ display: 'flex' }}>
              <ExternalLink color={color} height={14} width={14} />
            </div>
          )}
        </a>
      )}
    </div>
  )
}

CardAlertBanner.propTypes = {
  data: PropTypes.shape({
    bannerType: PropTypes.oneOf([
      ALERT_TYPE.INFORMATIONAL,
      ALERT_TYPE.NOTIFICATION,
      ALERT_TYPE.WARNING,
    ]),
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
}
