import { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Link from 'next/link'
import PropTypes from 'prop-types'
import theme, { SECONDARY_COLOR, FONTS, COLOR } from 'src/styles/theme'
import PresidioLogo from 'src/components/icons/FullLogo'
import { rem } from 'src/common/utils/css/index'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'

const commonStyle = {
  headingDiv: {
    borderBottom: `1px solid ${SECONDARY_COLOR.DARK[60]}`,
    paddingBottom: '7px',
    color: COLOR.NEAR_WHITE,
    'a:hover': {
      color: SECONDARY_COLOR.LIGHT[60],
      textDecoration: 'none',
    },
  },
}

const useStyles = makeStyles(theme)((props) => ({
  root: {
    '& a': {
      fontFamily: `"${FONTS.TABLET_GOTHIC}", ${FONTS.FALLBACK}`,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: '130%',
      textDecoration: 'none',
      color: theme.palette.presidio.color.NEAR_WHITE,
      '&:hover': {
        textDecoration: 'underline',
        color: SECONDARY_COLOR.LIGHT[60],
      },
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: rem(32),
    flex: 'none',
    order: '0',
    alignSelf: 'stretch',
    flexGrow: '0',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 'none',
    order: '0',
    alignSelf: 'stretch',
    flexGrow: '0',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingLeft: '0',
    },
  },
  listHead: {
    ...theme.typography.cardTitle,
    color: theme.palette.presidio.color.BAKER_BEACH_WHITE,
  },
  listHeadTitle: {
    a: {
      ...theme.typography.h4,
    },
    ...commonStyle.headingDiv,
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: rem(48),
    gap: rem(16),
    flex: 'none',
    order: '0',
    alignSelf: 'stretch',
    flexGrow: '0',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 33.333333%',
    },
  },
  list: {
    padding: `${rem(5)} 0`,
  },
  unorderedList: {
    listStyleType: 'none' /* Remove bullets */,
    padding: 0 /* Remove padding */,
    margin: 0,
  },
  typo: {
    color: theme.palette.presidio.color.BAKER_BEACH_WHITE,
  },
  headingDiv: {
    a: {
      ...theme.typography.h4,
    },
    ...commonStyle.headingDiv,
  },
}))

const LinkItem = ({ title, url }) => {
  const iconDefaultColor = theme.palette.presidio.color.NEAR_WHITE
  const iconHoverColor = SECONDARY_COLOR.LIGHT[60]
  const [colorIcon, setColorIcon] = useState(iconDefaultColor)

  const handleMouseEnter = (e) => {
    setColorIcon(iconHoverColor)
  }
  const handleMouseLeave = (e) => {
    setColorIcon(iconDefaultColor)
  }
  return (
    <Link href={url}>
      <a
        data-ga-location="footer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {title}{' '}
        {isUrlExternal(url) && (
          <ButtonExternalLink color={colorIcon} bottomMargin={-1} />
        )}
      </a>
    </Link>
  )
}

const BottomMenu = (props) => {
  const { data } = props
  const menu = Object.values(data)
  const { classes } = useStyles(props)
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  return (
    <>
      <div>
        <PresidioLogo color={theme.palette.presidio.color.NEAR_WHITE} />
      </div>
      <div className={classes.root}>
        <div className={classes.topRow}>
          {menu.map((menuItem, iIndex) => (
            <div key={iIndex} className={classes.menuItem}>
              <ul className={classes.unorderedList}>
                <li className={classes.listHead}>
                  <div
                    className={
                      lgUp ? classes.headingDiv : classes.listHeadTitle
                    }
                  >
                    <Link href={menuItem?.url}>
                      <a>{menuItem?.title}</a>
                    </Link>
                  </div>
                </li>

                {Object.hasOwn(menuItem, 'menuitem')
                  ? Object.values(menuItem.menuitem).map((item, jIndex) => (
                      <li key={jIndex} className={classes.list}>
                        <LinkItem title={item?.title} url={item?.url} />
                      </li>
                    ))
                  : ''}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default BottomMenu

BottomMenu.propTypes = {
  data: PropTypes.shape({
    1: PropTypes.shape({
      menuitem: PropTypes.object,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
}
