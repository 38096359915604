/* eslint-disable prefer-destructuring */
import React from 'react'
import Image from 'next/image'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Dialog, Box, DialogContent, DialogActions, Slide } from '@mui/material'
import theme, { SECONDARY_COLOR } from 'src/styles/theme'
import { rem } from 'src/common/utils/css'
import NewsletterMenu from 'src/components/main-navigation/drawer/NewsletterMenu'
import CrossIcon from 'src/components/icons/Cross'

const useStyles = makeStyles()(() => ({
  imageContainer: {
    display: 'flex',
    height: '402px',
    minWidth: '355px',
    overflow: 'hidden',
  },
  newsletterContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${rem(40)} 0`,
    overflow: 'hidden',
  },
}))

const SlideTransition = React.forwardRef((props, ref) => (
  <Slide direction="up" from="down" ref={ref} {...props} />
))

export default function NewsletterDialog(props) {
  const { classes } = useStyles()

  const { open, handleClose, footerData, slideTransition } = props

  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))

  const transitionComponent = slideTransition ? SlideTransition : undefined

  const newsletterData = footerData?.footer?.news_letter

  let filteredNewsLetterData
  if (newsletterData && Array.isArray(newsletterData)) {
    filteredNewsLetterData = newsletterData.filter(
      (x) => x.default_mailing_list === true
    )[0]
  }

  const { footer_newsletter_popup_image } = filteredNewsLetterData || {}

  const { url, alt, title } = footer_newsletter_popup_image || {}

  return (
    <Dialog
      role="dialog"
      aria-modal="true"
      open={open}
      keepMounted
      onClose={handleClose}
      aria-label="newsletter"
      aria-labelledby="newsletter-title"
      aria-describedby="newsletter-description"
      fullWidth
      maxWidth={false}
      TransitionComponent={transitionComponent}
      transitionDuration={{ enter: 500, exit: 100 }}
      PaperProps={{
        style: {
          width: `${lgUp ? '740px' : '100%'}`,
          height: `${lgUp ? '402px' : 'auto'}`,
          maxWidth: 'unset',
          maxHeight: 'unset',
          background: SECONDARY_COLOR.LIGHT[40],
          margin: 0,
          position: `${lgUp ? 'relative' : 'fixed'}`,
          bottom: `${lgUp ? 'unset' : '0'}`,
          top: `${lgUp ? 'unset' : 'auto'}`,
        },
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(73, 55, 38, 0.70)',
        },
      }}
    >
      <DialogContent
        sx={{
          padding: `${lgUp ? '0 40px 0 0' : '0'}`,
          position: 'relative',
          display: 'flex',
          gap: rem(24),
        }}
      >
        {lgUp && (
          <div className={classes.imageContainer}>
            <Image
              src={url || '/assets/newsletter/newsletter-popup.png'}
              alt={alt || 'Presidio Park'}
              title={title}
              height="402"
              width="355"
              quality={100}
              objectFit="cover"
            />
          </div>
        )}
        <Box className={classes.newsletterContainer}>
          <NewsletterMenu footerData={footerData} />
        </Box>
      </DialogContent>
      <DialogActions
        onClick={handleClose}
        sx={{
          padding: 0,
          position: 'absolute',
          top: 8,
          right: 8,
          cursor: 'pointer',
        }}
      >
        <CrossIcon width="32" height="32" color={theme.palette.primary.dark} />
      </DialogActions>
    </Dialog>
  )
}

NewsletterDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  footerData: PropTypes.object,
  slideTransition: PropTypes.bool,
}
