import { Box, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import theme, { COLOR, FONTS } from 'src/styles/theme'
import SearchIcon from 'src/components/icons/Search'
import SearchInput from 'src/components/main-navigation/search-bar/SearchInput'
import { rem } from 'src/common/utils/css'

const useStyles = makeStyles()((_theme, props) => ({
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    '&: hover': {
      color: theme.palette.primary.dark,
    },
    '&: hover span svg path': {
      stroke: theme.palette.primary.dark,
    },
    [theme.breakpoints.up('lg')]: {
      width: rem(180),
      padding: `${rem(0)} ${rem(12)}`,
      justifyContent: 'unset',
      background: COLOR.NEAR_WHITE,
      border: `1px solid ${COLOR.DARK_GRAY}`,
      borderRadius: `${rem(5)}`,
    },
    '@media (min-width: 1024px) and (max-width: 1124px)': {
      width: 'auto',
    },
  },
  searchIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&: hover': {
      color: theme.palette.primary.dark,
    },
  },
  searchbar: {
    ...theme.typography.h4,
    [theme.breakpoints.up('lg')]: {
      cursor: 'pointer',
      background: 'transparent',
      marginBottom: '2px', // because we have this margin for other tabs for active tab feature
      color: COLOR.MID_GRAY,
      fontSize: rem(16),
      textTransform: 'unset',
      fontFamily: FONTS.TABLET_GOTHIC,
      paddingLeft: rem(8),
    },
  },
}))

const Search = (props) => {
  const { classes } = useStyles(props)

  const { iconColor, iconWidth, iconHeight, showIconOnly, label, tabIndex } =
    props

  const [showSearch, setShowSearch] = useState(false)

  // Icon size is different on lg and xl screens
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))

  const searchClickHandler = (e) => {
    if (e?.type === 'click' || e?.key === 'Enter') {
      setShowSearch(true)
    }
  }

  return (
    <div
      className={classes.searchContainer}
      tabIndex={tabIndex}
      onClick={(e) => searchClickHandler(e)}
      onKeyDown={(e) => searchClickHandler(e)}
      data-ga-location="topnav"
    >
      <span className={classes.searchIcon}>
        {lgUp ? (
          <SearchIcon width={22} height={30} color={iconColor} />
        ) : (
          <SearchIcon width={iconWidth} height={iconHeight} color={iconColor} />
        )}
      </span>
      {!showIconOnly && (
        <Box className={classes.searchbar} htmlFor="my-search-input">
          {label}
        </Box>
      )}
      {showSearch && <SearchInput setShowSearch={setShowSearch} />}
    </div>
  )
}

export default Search

Search.propTypes = {
  iconColor: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  showIconOnly: PropTypes.bool,
  label: PropTypes.string,
}
