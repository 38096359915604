import { Box } from '@mui/material'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from 'src/common/components/button/Button'
import { rem } from 'src/common/utils/css'
import SearchIcon from 'src/components/icons/Search'
import theme, { COLOR } from 'src/styles/theme'
import { getSearchURL } from 'src/components/main-navigation/search-bar/helper'

const useStyles = makeStyles()((defaultTheme, props) => ({
  searchInputBox: {
    backgroundColor: COLOR.BAKER_BEACH_WHITE,
    position: 'absolute',
    top: 0,
    left: 0,
    padding: `0 ${rem(24)}`,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: rem(16),
    zIndex: 10,
    opacity: 0,
    transition: `all ${props.animationTime}ms ease-in-out`,
    [theme.breakpoints.up('md')]: {
      padding: `0 ${rem(144)}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `0 ${rem(228)}`,
      gap: rem(24),
    },
  },
  inputBox: {
    backgroundColor: COLOR.NEAR_WHITE,
    padding: `${rem(4)} ${rem(16)}`,
    display: 'flex',
    alignItems: 'center',
    gap: rem(8),
    border: `${rem(2)} solid ${theme.palette.primary.dark}`,
    borderRadius: rem(4),
    width: rem(0),
    overflow: 'hidden',
    transition: `all ${props.animationTime}ms ease-in-out`,
  },
  input: {
    ...theme.typography.largeBody.default,
    width: '100%',
    padding: 0,
    paddingBottom: rem(2),
    color: COLOR.BLACK,
    border: 'none',
    outline: 'none',
  },
  iconBox: {
    width: rem(22),
    height: rem(30),
    cursor: 'pointer',
  },
  closeButton: {
    padding: `${rem(4)} 0 ${rem(5)} 0`,
  },
}))

export default function SearchInput(props) {
  const { setShowSearch } = props

  const [searchText, setSearchText] = useState('')
  const searchInputBox = useRef(null)
  const inputElement = useRef(null)
  const inputElementBox = useRef(null)

  const animationTime = 800

  const router = useRouter()
  const { classes } = useStyles({ animationTime })

  const closeSearchBar = () => {
    setTimeout(() => {
      if (searchInputBox?.current && inputElementBox?.current) {
        searchInputBox.current.style.opacity = 0
        inputElementBox.current.style.width = rem(0)
      }
    }, 0)
    setTimeout(() => {
      if (setShowSearch instanceof Function) setShowSearch(false)
    }, animationTime)
  }

  const handleSearch = () => {
    const searchURL = getSearchURL(null, null, searchText)
    if (searchURL) {
      router.push(`/search?${searchURL}`)
    }
  }

  const handleClose = (e) => {
    if (e.type === 'click' || e.key === 'Enter') {
      e.stopPropagation()
      closeSearchBar()
    }
  }

  const handleKeyDown = (e) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleBlurInput = (e) => {
    const clickedElement = e?.nativeEvent?.explicitOriginalTarget
    if (
      clickedElement?.classList &&
      clickedElement.classList.length &&
      searchInputBox?.current &&
      inputElementBox?.current
    ) {
      const lastClassClickedElement =
        clickedElement.classList[clickedElement.classList.length - 1]
      const lastClassSearchInputBox =
        searchInputBox.current.classList[
          searchInputBox.current.classList.length - 1
        ]
      const lastClassInputElementBox =
        inputElementBox.current.classList[
          inputElementBox.current.classList.length - 1
        ]

      if (
        !(
          lastClassClickedElement === lastClassInputElementBox ||
          lastClassClickedElement === lastClassSearchInputBox ||
          lastClassClickedElement === undefined
        )
      ) {
        closeSearchBar()
      }
    } else if (!clickedElement?.parentElement?.id === 'searchiconbox') {
      closeSearchBar()
    }
  }

  const handleClickBox = (e) => {
    e.stopPropagation()
    inputElement?.current?.focus()
  }

  useEffect(() => {
    if (searchInputBox?.current && inputElementBox?.current) {
      const { gap, paddingLeft, paddingRight, width } = window.getComputedStyle(
        searchInputBox.current
      )
      const { width: buttonWidth } = window.getComputedStyle(
        searchInputBox.current.childNodes[1]
      )
      const requiredWidth =
        parseInt(width, 10) -
        parseInt(paddingLeft, 10) -
        parseInt(paddingRight, 10) -
        parseInt(gap, 10) -
        parseInt(buttonWidth, 10)

      setTimeout(() => {
        searchInputBox.current.style.opacity = 1
        inputElementBox.current.style.width = rem(requiredWidth)
      }, 0)
      setTimeout(() => {
        inputElement?.current?.focus()
      }, animationTime)
    }
  }, [])

  return (
    <Box
      ref={searchInputBox}
      className={classes.searchInputBox}
      onClick={(e) => handleClickBox(e)}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Box ref={inputElementBox} className={classes.inputBox}>
        <input
          ref={inputElement}
          className={classes.input}
          data-weglot="translate"
          placeholder=" Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          onBlur={(e) => handleBlurInput(e)}
        />
        <Box
          id="searchiconbox"
          className={classes.iconBox}
          onClick={(e) => handleSearch(e)}
        >
          <SearchIcon
            width={22}
            height={30}
            color={theme.palette.primary.dark}
          />
        </Box>
      </Box>
      <Button
        className={classes.closeButton}
        variant="text"
        onClick={(e) => handleClose(e)}
        onKeyDown={(e) => handleClose(e)}
        tabIndex={0}
        data-testid="search-close"
        data-weglot="translate"
      >
        <span className="zh-keepWord">Close</span>
      </Button>
    </Box>
  )
}

SearchInput.propTypes = {
  setShowSearch: PropTypes.func,
}
