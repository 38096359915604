import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Link from 'next/link'
import PropTypes from 'prop-types'
import theme, { SECONDARY_COLOR } from 'src/styles/theme'
import FbIcon from 'src/components/icons/FbIcon'
import InstagramIcon from 'src/components/icons/InstagramIcon'
import LinkdinIcon from 'src/components/icons/LinkdinIcon'
import YoutubeIcon from 'src/components/icons/YoutubeIcon'
import sanitize from 'src/common/utils/js/sanitize'
import { rem } from 'src/common/utils/css/index'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import XCorp from 'src/components/icons/XCorp'

const useStyles = makeStyles(theme)((props) => ({
  socialMediaBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'left',
    padding: 0,
    flex: 'none',
    flexGrow: '0',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  socialLinks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: rem(19),
    flex: 'none',
    order: '0',
    flexGrow: '0',
  },
  copyRightText: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'left',
    padding: 0,
    gap: 0,
    flex: 'none',
    order: '1',
    flexGrow: '0',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: rem(20),
      alignItems: 'center',
    },
  },
  socialMedia: {
    display: 'inline-flex',
    marginTop: rem(30),
  },
  copyRight: {
    ...theme.typography.helperText,
    justifyContent: 'start',
    paddingTop: rem(30),
    color: theme.palette.presidio.color.NEAR_WHITE,
    [theme.breakpoints.up('md')]: {
      display: 'contents',
      justifyContent: 'end',
      marginTop: rem(17),
      padding: `${rem(12)} 0rem`,
    },
  },
  copyRightLinks: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'left',
    padding: 0,
    gap: 0,
    flex: 'none',
    order: '1',
    flexGrow: '0',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
    '& a:nth-of-type(1)': {
      marginLeft: rem(3),
    },
    '& a': {
      ...theme.typography.body.inlineLink,
      ...theme.typography.helperText,
      lineHeight: '135%',
      color: theme.palette.presidio.color.NEAR_WHITE,
      marginLeft: rem(5),
      '&:after': {
        ...theme.typography.body.inlineLink['&:after'],
        bottom: 0,
      },
    },
  },

  link: {
    color: theme.palette.presidio.color.NEAR_WHITE,
  },
}))
const SocialMediaIcon = (prop) => {
  const { classes } = useStyles(prop)
  const { iconData } = prop || {}
  const [hoverColor, setHoverColor] = useState(null)
  if (iconData?.title === 'Facebook') {
    return (
      <div
        key={iconData?.index}
        onMouseEnter={() => setHoverColor(SECONDARY_COLOR.LIGHT[60])}
        onMouseLeave={() => setHoverColor(null)}
        className={classes.socialMedia}
      >
        <a
          href={iconData?.url}
          aria-label="Facebook"
          data-ga-location="social_follow"
        >
          <FbIcon color={hoverColor} />
        </a>
      </div>
    )
  }
  if (iconData?.title === 'Youtube') {
    return (
      <div
        key={iconData?.index}
        onMouseEnter={() => setHoverColor(SECONDARY_COLOR.LIGHT[60])}
        onMouseLeave={() => setHoverColor(null)}
        className={classes.socialMedia}
      >
        <a
          href={iconData?.url}
          aria-label="Youtube"
          data-ga-location="social_follow"
        >
          <YoutubeIcon color={hoverColor} />
        </a>
      </div>
    )
  }
  if (iconData?.title === 'Twitter') {
    return (
      <div
        key={iconData?.index}
        onMouseEnter={() => setHoverColor(SECONDARY_COLOR.LIGHT[60])}
        onMouseLeave={() => setHoverColor(null)}
        className={classes.socialMedia}
      >
        <a
          href={iconData?.url}
          aria-label="Twitter"
          data-ga-location="social_follow"
        >
          <XCorp color={hoverColor} />
        </a>
      </div>
    )
  }
  if (iconData?.title === 'Linkdin') {
    return (
      <div
        key={iconData?.index}
        onMouseEnter={() => setHoverColor(SECONDARY_COLOR.LIGHT[60])}
        onMouseLeave={() => setHoverColor(null)}
        className={classes.socialMedia}
      >
        <a
          href={iconData?.url}
          aria-label="Linkdin"
          data-ga-location="social_follow"
        >
          <LinkdinIcon color={hoverColor} />
        </a>
      </div>
    )
  }
  if (iconData?.title === 'Instagram') {
    return (
      <div
        key={iconData?.index}
        onMouseEnter={() => setHoverColor(SECONDARY_COLOR.LIGHT[60])}
        onMouseLeave={() => setHoverColor(null)}
        className={classes.socialMedia}
      >
        <a
          href={iconData?.url}
          aria-label="Instagram"
          data-ga-location="social_follow"
        >
          <InstagramIcon color={hoverColor} />
        </a>
      </div>
    )
  }
  return ''
}

const SocialMedia = (props) => {
  const { classes } = useStyles(props)
  const { data } = props
  const { links } = data.footer.copy
  return (
    <div className={classes.socialMediaBlock}>
      {data.footer.social_menu.menu_items.length > 0 ? (
        <div className={classes.socialLinks}>
          {Object.values(data.footer.social_menu.menu_items).map((i, index) => (
            <SocialMediaIcon key={`${index}icon`} iconData={{ ...i, index }} />
          ))}
        </div>
      ) : (
        ''
      )}
      <div className={classes.copyRightText}>
        <div className={classes.copyRight}>
          <span>{sanitize(data?.footer?.copy?.text)}</span>
          <div>
            <div className={classes.copyRightLinks}>
              {links &&
                links.length !== 0 &&
                links.map((linkItem, index) => (
                  <>
                    <Link
                      style={{
                        display: links.length > 1 ? 'table-cell' : 'inline',
                      }}
                      key={index}
                      target={linkItem?.links?.target}
                      href={linkItem?.links?.url}
                    >
                      <a data-ga-location="utility_footer">
                        {linkItem?.links?.title}
                      </a>
                    </Link>

                    {isUrlExternal(linkItem?.links?.url) && (
                      <ButtonExternalLink
                        height={14}
                        width={14}
                        color={theme.palette.presidio.color.NEAR_WHITE}
                        bottomMargin={-1}
                      />
                    )}
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SocialMedia

SocialMedia.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    menu_items: PropTypes.shape({
      0: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  }),
}
