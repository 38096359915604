/**
 * Checks if a given link is an external link or an internal link.
 *
 * Internal links - start with a '/', '#', or have domain '.gov' or '.mil'.
 *
 * External links - domain other than '.gov' or '.mil'.
 *
 * @param {string} link - The string to be checked
 * @return {boolean} - `true` for an external link.
 *                    - `false` for an internal link or strings without a dot.
 *
 */
export default function isUrlExternal(link) {
  try {
    if (
      link.startsWith('/') ||
      link.startsWith('../') ||
      link.startsWith('#') ||
      link.startsWith('tel') ||
      link.startsWith('mailto') ||
      link.includes('.go-vip.net')
    )
      return false // Internal link

    // required to add http/https in links such as 'google.com or 'www.google.com'
    if (link.includes('.'))
      if (!link.startsWith('http')) {
        link = `http://${link}`
      }

    const allowedDomains = ['.gov', '.mil']
    const url = new URL(link)
    const domain = url.hostname.split('.').slice(-1)[0].toLowerCase() // Get the last part of the domain (e.g. - com, gov, etc)
    return !allowedDomains.includes(`.${domain}`)
  } catch (error) {
    // catch invalid URL such as a string without a dot.
    return false
  }
}
