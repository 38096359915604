import React from 'react'

function InstagramIcon(props) {
  const { color, height, width } = props
  return (
    <svg
      width={width || '18'}
      height={height || '19'}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99776 6.66962C7.34514 6.66962 5.99648 8.01828 5.99648 9.6709C5.99648 11.3235 7.34514 12.6722 8.99776 12.6722C10.6504 12.6722 11.999 11.3235 11.999 9.6709C11.999 8.01828 10.6504 6.66962 8.99776 6.66962ZM17.9994 9.6709C17.9994 8.42806 18.0106 7.19648 17.9408 5.95589C17.871 4.51491 17.5423 3.23605 16.4886 2.18234C15.4326 1.12638 14.156 0.799905 12.715 0.730108C11.4722 0.660311 10.2406 0.671568 9.00001 0.671568C7.75717 0.671568 6.52559 0.660311 5.285 0.730108C3.84402 0.799905 2.56516 1.12863 1.51144 2.18234C0.455478 3.2383 0.129007 4.51491 0.0592093 5.95589C-0.010588 7.19873 0.000669703 8.43031 0.000669703 9.6709C0.000669703 10.9115 -0.010588 12.1453 0.0592093 13.3859C0.129007 14.8269 0.457729 16.1057 1.51144 17.1595C2.56741 18.2154 3.84402 18.5419 5.285 18.6117C6.52784 18.6815 7.75942 18.6702 9.00001 18.6702C10.2429 18.6702 11.4744 18.6815 12.715 18.6117C14.156 18.5419 15.4349 18.2132 16.4886 17.1595C17.5445 16.1035 17.871 14.8269 17.9408 13.3859C18.0129 12.1453 17.9994 10.9137 17.9994 9.6709ZM8.99776 14.2888C6.44228 14.2888 4.37989 12.2264 4.37989 9.6709C4.37989 7.11542 6.44228 5.05303 8.99776 5.05303C11.5532 5.05303 13.6156 7.11542 13.6156 9.6709C13.6156 12.2264 11.5532 14.2888 8.99776 14.2888ZM13.8048 5.94238C13.2081 5.94238 12.7263 5.46055 12.7263 4.8639C12.7263 4.26725 13.2081 3.78542 13.8048 3.78542C14.4014 3.78542 14.8832 4.26725 14.8832 4.8639C14.8834 5.00558 14.8557 5.1459 14.8015 5.27683C14.7474 5.40775 14.6679 5.52672 14.5678 5.6269C14.4676 5.72708 14.3486 5.80651 14.2177 5.86065C14.0868 5.91478 13.9464 5.94256 13.8048 5.94238Z"
        fill={color || '#FEFCF8'}
      />
    </svg>
  )
}

export default InstagramIcon
