/* eslint-disable radix */
import { useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { APPBAR_HEIGHT } from 'src/common/constants'
import AlertBanner from 'src/components/alert-banner/AlertBanner'
import Footer from 'src/components/footer/Footer'
import Header from 'src/components/header/Header'
import NewsletterDialog from 'src/components/header/NewsletterDialog'
import MainNavigation from 'src/components/main-navigation/MainNavigation'
import theme from 'src/styles/theme'

function Layout(props) {
  const { children } = props
  const {
    headerData,
    footerData,
    mainNavigationData,
    alertBannerData,
    newsletterMailingId,
  } = props

  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false)

  useEffect(() => {
    const newsletterPopupState = localStorage.getItem('newsletterPopupState')
    const twoWeeksInMillis = 2 * 7 * 24 * 60 * 60 * 1000

    let timer
    if (
      !newsletterPopupState ||
      Date.now() - parseInt(newsletterPopupState, 10) > twoWeeksInMillis
    ) {
      timer = setTimeout(() => {
        setShowNewsletterPopup(true)
      }, 3000)
    }
    return () => clearTimeout(timer)
  }, [])

  const handleNewsletterPopupClose = () => {
    setShowNewsletterPopup(false)

    localStorage.setItem('newsletterPopupState', Date.now().toString())
  }

  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const xlUp = useMediaQuery(() => theme.breakpoints.up('xl'))

  useEffect(() => {
    function addTopPadding(firstModule) {
      firstModule.setAttribute('id', 'main-content')

      const existingPaddingTop = window.getComputedStyle(firstModule).paddingTop

      const requiredAdditionalPadding = 36 // 50% of the appbar height
      firstModule.style.marginTop = '-36px' // 50% of the appbar height

      if (parseInt(existingPaddingTop, 10) < 65) {
        firstModule.style.paddingTop = existingPaddingTop
          ? `${
              parseInt(
                existingPaddingTop.substring(0, existingPaddingTop.length - 2)
              ) + requiredAdditionalPadding
            }px`
          : requiredAdditionalPadding
      }
    }

    const firstModule = document.querySelector('.module:first-child')

    if (firstModule) {
      addTopPadding(firstModule)
    } else {
      setTimeout(() => {
        const firstModuleRetry = document.querySelector('.module:first-child')
        addTopPadding(firstModuleRetry)
      }, 0)
    }

    // If last module is not null, add the padding immediately, else retry after 3-4 seconds

    function addBottomPadding(lastModule) {
      // determine additional padding required to the last module based on the newsletter height
      const newsletter = document.querySelector('[data-id="newsletter"]')

      if (!newsletter) return

      const requiredAdditionalPadding = newsletter.clientHeight / 2

      if (lastModule && !lastModule?.classList?.contains('no-bottom-padding')) {
        const existingPadding =
          window.getComputedStyle(lastModule).paddingBottom

        if (parseInt(existingPadding, 10) >= requiredAdditionalPadding) return

        const newPadding = `${
          parseInt(existingPadding.substring(0, existingPadding.length - 2)) +
          requiredAdditionalPadding
        }px`

        lastModule.style.paddingBottom = newPadding
      }
    }

    const lastModule = document.querySelector('.module:last-child')

    if (lastModule) {
      addBottomPadding(lastModule)
    } else {
      setTimeout(() => {
        const lastModuleRetry1 = document.querySelector('.module:last-child')

        if (lastModuleRetry1) {
          addBottomPadding(lastModuleRetry1)
        }
      }, 0)
      setTimeout(() => {
        const lastModuleRetry2 = document.querySelector('.module:last-child')

        if (lastModuleRetry2) {
          addBottomPadding(lastModuleRetry2)
        }
      }, 2500)
    }
  })

  useEffect(() => {
    // determine additional padding required to the text part of the text & media module |fullbleed & semicircle media variants based on the newsletter height
    const newsletter = document.querySelector('[data-id="newsletter"]')

    if (!newsletter) return

    const requiredAdditionalPadding = newsletter.clientHeight / 2

    const lastModule = document.querySelector('.module:last-child')

    if (
      lastModule?.classList?.contains('no-bottom-padding') &&
      lastModule?.classList?.contains('full-media')
    ) {
      const moduleHeight = parseInt(window.getComputedStyle(lastModule).height)

      const textPart = lastModule.childNodes[0]
      const { height } = window.getComputedStyle(textPart)

      if (lgUp && parseInt(height) > moduleHeight - 80) {
        textPart.style.paddingBottom = `${60 + requiredAdditionalPadding}px`
        textPart.style.paddingTop = `${60 + requiredAdditionalPadding}px`
      } else if (xlUp && parseInt(height) > moduleHeight - 100) {
        textPart.style.paddingBottom = `${64 + requiredAdditionalPadding}px`
        textPart.style.paddingTop = `${64 + requiredAdditionalPadding}px`
      }
    }

    // add bottom padding to last text full-media on mobile/tablet
    if (!lgUp && lastModule?.classList?.contains('full-media')) {
      lastModule.style.paddingBottom = `${requiredAdditionalPadding + 60}px`
    }
  })

  useEffect(() => {
    // set offset of a section from top
    const navbarHeight = 72 // appbar height
    const segmentedControl = document.querySelector('#segmented-control')

    if (!segmentedControl) return

    const segmentedControlHeight =
      window.getComputedStyle(segmentedControl).height

    const sectionOffset = `${
      parseInt(
        segmentedControlHeight.substring(0, segmentedControlHeight.length - 2)
      ) + navbarHeight
    }px`
    const sections = document.querySelectorAll('.module')
    sections.forEach((section) => {
      section.style.scrollMarginTop = sectionOffset
    })
  })

  return (
    <div>
      <AlertBanner data={alertBannerData?.global_alert_banner} isGlobal />

      {lgUp && (
        <Header
          headerData={headerData}
          footerData={{ ...footerData, newsletterMailingId }}
        />
      )}

      <NewsletterDialog
        open={showNewsletterPopup}
        handleClose={handleNewsletterPopupClose}
        footerData={{ ...footerData, newsletterMailingId }}
        slideTransition={!lgUp}
      />

      <MainNavigation
        mainNavigationData={mainNavigationData}
        headerData={headerData}
        footerData={{ ...footerData, newsletterMailingId }}
      />

      {/* Push down rest of the app here */}
      <div style={{ marginBottom: APPBAR_HEIGHT }} />

      {children}

      <Footer footerData={{ ...footerData, newsletterMailingId }} />
    </div>
  )
}

export default Layout
