export function getSearchURL(page, filters, searchString) {
  const searchURL = []

  const text = encodeURIComponent(
    searchString
      .trim()
      .split(' ')
      .filter((char) => char !== '')
      .join('+')
  ).replaceAll('%2B', '+')

  if (text) {
    searchURL.push(`s=${text}`)
  } else {
    return null
  }
  if (Array.isArray(filters)) {
    filters.forEach((filter) => {
      Object.entries(filter).forEach(([filterName, checkedOptions]) => {
        if (checkedOptions.length > 0) {
          searchURL.push(`${filterName}=${checkedOptions.join(',')}`)
        }
      })
    })
  }
  if (page) {
    searchURL.push(`page=${page}`)
  }

  return searchURL.join('&')
}
