import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'
import PresidioLogo from 'src/components/icons/FullLogo'
import theme, { COLOR } from 'src/styles/theme'
import ClockIcon from 'src/components/icons/ClockIcon'
import MailIcon from 'src/components/icons/Mail2'
import Weather from 'src/components/header/weather/Weather'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'
import NewsletterDialog from 'src/components/header/NewsletterDialog'

const useStyles = makeStyles(theme)((props) => ({
  header: {
    position: 'initial',
    zIndex: '100',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
  },
  topHeader: {
    ...theme.typography.body.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px 0 32px 0',
    gap: '12px',
    background: theme.palette.presidio.color.LIGHT_BACKGROUND,
    flex: 'none',
    order: '0',
    flexGrow: '0',
    [theme.breakpoints.up('lg')]: {
      padding: '24px 40px 60px',
      height: '208px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '24px 156px 60px',
    },
  },
  utilityBar: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0px 24px 0 24px',
    gap: '10px',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    [theme.breakpoints.up('lg')]: {
      padding: '0px',
      flexDirection: 'row',
      height: '27px',
      order: '0',
    },
    [theme.breakpoints.up('xl')]: {
      height: '27px',
    },
  },
  logoAndLinks: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px 0 24px',
    width: '100%',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    [theme.breakpoints.up('lg')]: {
      padding: '24px 0 16px',
      order: '1',
    },
  },
  weatherAndVisitorCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    padding: '0px',
    gap: '32px',
    [theme.breakpoints.up('lg')]: {
      height: '27px',
      width: '438px',
      order: '0',
      flexDirection: 'row',
      gap: '16px',
      marginTop: '5px',
    },
  },
  languageToggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: '40px 0 50px',
    gap: '8px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    [theme.breakpoints.up('lg')]: {
      order: '1',
      padding: '0px',
    },
  },
  linkText: {
    color: theme.palette.primary.dark,
    '& a': {
      ...theme.typography.body.inlineLink,
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
  line: {
    width: '19px',
    height: '0px',
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: '11px',
    transform: 'rotate(90deg)',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    borderRadius: '1px',
  },
  visitorCenterWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
    flex: 'none',
    order: '2',
    flexGrow: '0',
  },
  newsletterSignupWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    [theme.breakpoints.up('lg')]: {
      marginTop: '5px',
    },
  },
  newsletterSignupTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
    flex: 'none',
    order: '3',
    flexGrow: '0',
  },
  spanText: {
    paddingLeft: '5px',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      padding: '0px',
    },
  },
  headingDiv: {
    ...theme.typography.h4,
    fontSize: '24px',
    borderBottom: '2px solid transparent',
    '&: hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '@media (min-width:1023px) and (max-width:1048px)': {
      /*
       * This change is due to the new design.
       * Hardcoded media queries have been added because a
       * media query in the h4 font is changing the font-size
       * to 1.5 rem.
       * */
      fontSize: '1.125rem',
    },
  },
  linksText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '8px 0 4px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    borderBottom: `2px solid transparent`,
    '& a': {
      color: theme.palette.presidio.color.DARK_GRAY,
      textDecoration: 'none',
      padding: '8px 8px 4px 0',
      '&: hover': {
        color: theme.palette.primary.dark,
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& a': {
        padding: '8px 8px 4px 8px',
      },
    },
  },
  icon: {
    marginTop: '5px',
    display: 'inline',
    paddingTop: '2px',
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      marginTop: '0px',
    },
  },
  logo: {
    cursor: 'pointer',
  },
}))

// Following are the desktop first styles for  this component
// const useStyles = makeStyles(theme)((props) => ({
//   topHeader: {
//     ...theme.typography.body.default,
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     padding: '24px 156px 60px',
//     gap: '12px',
//     background: theme.palette.presidio.color.LIGHT_BACKGROUND,
//     flex: 'none',
//     order: '0',
//     flexGrow: '0',
//     height: '208px',
//     [theme.breakpoints.down('xl')]: {
//       padding: '24px 40px 60px',
//     },
//     [theme.breakpoints.down('md')]: {
//       padding: '24px 0 32px 0',
//       height: 'unset',
//     },
//   },
//   utilityBar: {
//     display: 'flex',
//     width: '100%',
//     justifyContent: 'space-between',
//     alignItems: 'flex-start',
//     gap: '10px',
//     flex: 'none',
//     flexGrow: '0',
//     padding: '0px',
//     height: '27px',
//     order: '0',
//     [theme.breakpoints.down('md')]: {
//       flexDirection: 'column',
//       order: '1',
//       padding: '0px 24px 0 24px',
//       height: 'unset',
//     },
//   },
//   logoAndLinks: {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     width: '100%',
//     flex: 'none',
//     order: '1',
//     flexGrow: '0',
//     padding: '24px 0 16px',
//     [theme.breakpoints.down('md')]: {
//       padding: '0px 24px 0 24px',
//       order: '0',
//     },
//   },
//   weatherAndVisitorCenter: {
//     display: 'flex',
//     alignItems: 'flex-start',
//     flex: 'none',
//     flexGrow: '0',
//     padding: '0px',
//     height: '27px',
//     width: '438px',
//     order: '0',
//     gap: '16px',
//     marginTop: '5px',
//     [theme.breakpoints.down('md')]: {
//       height: 'unset',
//       width: '100%',
//       order: '1',
//       flexDirection: 'column',
//       gap: '32px',
//       marginTop: 'unset',
//     },
//   },
//   languageToggle: {
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'flex-end',
//     gap: '8px',
//     flex: 'none',
//     flexGrow: '0',
//     order: '1',
//     padding: '0px',
//     [theme.breakpoints.down('md')]: {
//       order: '0',
//       padding: '40px 0 50px',
//     },
//   },
//   linkText: {
//     color: theme.palette.primary.dark,
//     '& a': {
//       ...theme.typography.body.inlineLink,
//       color: theme.palette.primary.dark,
//       cursor: 'pointer',
//     },
//   },
//   line: {
//     width: '19px',
//     height: '0px',
//     border: `1px solid ${theme.palette.primary.main}`,
//     marginTop: '11px',
//     transform: 'rotate(90deg)',
//     flex: 'none',
//     order: '1',
//     flexGrow: '0',
//     borderRadius: '1px',
//   },
//   visitorCenterWrap: {
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     padding: '0px',
//     gap: '8px',
//     flex: 'none',
//     order: '2',
//     flexGrow: '0',
//   },
//   newsletterSignupWrapper: {
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     padding: '0px',
//     gap: '8px',
//     flex: 'none',
//     order: '0',
//     marginTop: '5px',
//     flexGrow: '0',
//     [theme.breakpoints.down('md')]: {
//       marginTop: 'unset',
//     },
//   },
//   newsletterSignupTextWrapper: {
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     padding: '0px',
//     gap: '8px',
//     flex: 'none',
//     order: '3',
//     flexGrow: '0',
//   },
//   spanText: {
//     paddingLeft: '5px',
//   },
//   links: {
//     display: 'flex',
//     alignItems: 'flex-start',
//     gap: '8px',
//     flex: 'none',
//     order: '1',
//     flexGrow: '0',
//     padding: '0px',
//     [theme.breakpoints.down('md')]: {
//       flexDirection: 'column',
//     },
//   },
//   headingDiv: {
//     ...theme.typography.h4,
//     fontSize: '24px',
//     borderBottom: '2px solid transparent',
//     '&: hover': {
//       borderBottom: `2px solid ${theme.palette.primary.main}`,
//     },
//   },
//   linksText: {
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'flex-start',
//     padding: '8px 0 4px',
//     flex: 'none',
//     order: '0',
//     flexGrow: '0',
//     borderBottom: `2px solid transparent`,
//     '& a': {
//       color: theme.palette.presidio.color.DARK_GRAY,
//       textDecoration: 'none',
//       padding: '8px 8px 4px 8px',
//       '&: hover': {
//         color: theme.palette.primary.dark,
//       },
//     },
//     [theme.breakpoints.down('md')]: {
//       '& a': {
//         padding: '8px 8px 4px 0',
//       },
//     },
//   },
//   icon: {
//     paddingTop: '2px',
//     display: 'grid',
//     marginTop: '0px',
//     [theme.breakpoints.down('md')]: {
//       display: 'inline',
//       marginTop: '5px',
//     },
//   },
//   logo: {
//     cursor: 'pointer',
//   },
// }))

const Header = (props) => {
  const { classes } = useStyles(props)
  const {
    headerData = {},
    footerData = {},
    onUtilityLinkClick = () => {},
    setOpenNestedDrawer = () => {},
    setShowNewsletterModule = () => {},
  } = props || {}

  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const lgDown = useMediaQuery(() => theme.breakpoints.down('lg'))

  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false)

  const router = useRouter()

  const linkClickHandler = useLinkClickHandler()

  // guards
  if (!headerData || Object.keys(headerData).length === 0) {
    return null
  }

  React.useEffect(() => {
    const setInter = setInterval(() => {
      const weglotContainerSource = window.weglotContainer
      const weglotContainerDest = document.getElementById(
        'weglotContainerParent'
      )

      if (weglotContainerSource && weglotContainerDest) {
        weglotContainerDest.appendChild(weglotContainerSource)
        clearInterval(setInter)
      }
    }, 500)
  }, [])

  /**
   * Checks whether the provided relative path matches the current page's path.
   * @param {string} link - The link to be checked.
   * @returns {boolean} - true if the link matches the current page's path.
   * @example
   * Assuming the current page's path is '/hello':
   * - isSameAsCurrentPath('/hello')   // true
   * - isSameAsCurrentPath('/hello/')  // true
   * - isSameAsCurrentPath('/hello#world') // true
   * - isSameAsCurrentPath('www.anything.gov/hello') // false
   */

  const isSameAsCurrentPath = (link) => {
    const currentPath = router?.asPath?.split('#')[0]
    return currentPath === link || `${currentPath}/` === link
  }

  const newsletterSignupWrapperDesktopElement = (
    <div className={classes.newsletterSignupWrapper}>
      <div className={classes.icon}>
        <MailIcon width="18" height="19" />
      </div>
      <div className={classes.newsletterSignupTextWrapper}>
        <span className={classes.linkText}>
          <a
            href="#"
            data-ga-location="header"
            onClick={(e) => {
              e.preventDefault()
              setShowNewsletterPopup(true)
            }}
          >
            Newsletter Sign Up
          </a>
        </span>
      </div>
    </div>
  )
  const newsletterSignupWrapperMobileElement = (
    <div className={classes.newsletterSignupWrapper}>
      <div className={classes.icon}>
        <MailIcon width="18" height="19" />
      </div>
      <div className={classes.newsletterSignupTextWrapper}>
        <span className={classes.linkText}>
          <span
            data-ga-location="header"
            onClick={(e) => {
              e.preventDefault()
              setOpenNestedDrawer(true)
              setShowNewsletterModule(true)
            }}
            onKeyDown={(e) => {
              e.preventDefault()
              setOpenNestedDrawer(true)
              setShowNewsletterModule(true)
            }}
            style={{ color: COLOR.DARK_GRAY }}
          >
            Newsletter Sign Up
          </span>
        </span>
      </div>
    </div>
  )

  const handleNewsletterPopupClose = () => {
    setShowNewsletterPopup(false)
  }

  return (
    <>
      <header data-weglot="translate" className={classes.header}>
        <div className={classes.topHeader}>
          <div className={classes.utilityBar}>
            <div className={classes.weatherAndVisitorCenter}>
              {lgDown && newsletterSignupWrapperMobileElement}
              <Weather data={headerData} />
              {lgUp && <div className={classes.line} />}
              <div className={classes.visitorCenterWrap}>
                <div className={classes.icon}>
                  <ClockIcon />
                </div>
                <div className={classes.visitorCenterTextWrap}>
                  <span className={classes.linkText}>
                    <a
                      href={headerData.header?.visitor_center?.url}
                      data-ga-location="header"
                      onClick={(e) => {
                        e.preventDefault()
                        onUtilityLinkClick()
                        linkClickHandler(headerData.header?.visitor_center)
                      }}
                    >
                      {headerData.header?.visitor_center?.title}
                    </a>
                    {isUrlExternal(headerData.header?.visitor_center?.url) && (
                      <ButtonExternalLink
                        height={16}
                        width={16}
                        color={theme.palette.primary.dark}
                        bottomMargin={-2}
                      />
                    )}
                  </span>
                  <span id="openTime" className={classes.spanText}>
                    {headerData.header?.open_time}
                  </span>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '40px' }}>
              {lgUp && <div>{newsletterSignupWrapperDesktopElement}</div>}
              <div
                className={classes.languageToggle}
                id="weglotContainerParent"
                data-ga-location="header"
              />
            </div>
          </div>
          <div className={classes.logoAndLinks}>
            {lgUp && (
              <div
                className={classes.logo}
                onClick={() => router.push('/')}
                onKeyDown={() => router.push('/')}
              >
                <PresidioLogo
                  width="200"
                  height="45"
                  fill={theme.palette.primary.dark}
                />
              </div>
            )}
            <div className={classes.links}>
              {headerData.header?.header_links?.map((i, index) => (
                <div key={index} className={classes.linksText}>
                  <a
                    href={i?.links?.url}
                    onClick={(e) => {
                      e.preventDefault()
                      onUtilityLinkClick()
                      linkClickHandler(i?.links)
                    }}
                    data-ga-location="utility_nav"
                  >
                    <div
                      className={classes.headingDiv}
                      style={
                        isSameAsCurrentPath(i?.links?.url?.split('#')[0])
                          ? {
                              borderBottom: `2px solid ${theme.palette.primary.main}`,
                              color: theme.palette.primary.dark,
                            }
                          : {}
                      }
                    >
                      {i?.links?.title.toUpperCase()}
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </header>
      <NewsletterDialog
        open={showNewsletterPopup}
        handleClose={handleNewsletterPopupClose}
        footerData={footerData}
      />
    </>
  )
}

export default Header
