import React from 'react'

function ClockIcon(props) {
  const { color, height, width } = props
  return (
    <svg
      width={width || '19'}
      height={height || '19'}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 9.5C2.75 5.77208 5.77208 2.75 9.5 2.75C13.2279 2.75 16.25 5.77208 16.25 9.5C16.25 13.2279 13.2279 16.25 9.5 16.25C5.77208 16.25 2.75 13.2279 2.75 9.5ZM9.5 1.25C4.94365 1.25 1.25 4.94365 1.25 9.5C1.25 14.0563 4.94365 17.75 9.5 17.75C14.0563 17.75 17.75 14.0563 17.75 9.5C17.75 4.94365 14.0563 1.25 9.5 1.25ZM10.25 5C10.25 4.58579 9.91421 4.25 9.5 4.25C9.08579 4.25 8.75 4.58579 8.75 5V9.5C8.75 9.78408 8.9105 10.0438 9.16459 10.1708L12.1646 11.6708C12.5351 11.8561 12.9856 11.7059 13.1708 11.3354C13.3561 10.9649 13.2059 10.5144 12.8354 10.3292L10.25 9.03647V5Z"
        fill={color || '#1C563D'}
      />
    </svg>
  )
}

export default ClockIcon
